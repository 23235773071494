import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";

export const DashboardAPI = {



    ///////////////
    /// COMPANY
    //////////////



    getCompanyById: async (id, cancel = false) => {

        const response = await api.request({
            url: "digiapp/company/" + id,
            method: "GET",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;



    },




    //////////////
    /// PROJECT
    //////////////

    postEmployee: async (data, cancel = false) => {
        const response = await api.request({
            url: "digiapp/emp",
            method: "POST",
            headers: { Authorization: `Bearer ${localStorage.getItem('auth')}` },
            data: { ...data, emp_id: localStorage.getItem('emp_id') },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;
    },

    getEmployee: async (cancel = false) => {
        const response = await api.request({
            url: "digiapp/emp",
            method: "GET",
            headers: { Authorization: `Bearer ${localStorage.getItem('auth')}` },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    putEmployee: async (id, data, cancel = false) => {
        const response = await api.request({
            url: "digiapp/emp" + "/" + id,
            method: "PUT",
            headers: { Authorization: `Bearer ${localStorage.getItem('auth')}` },
            data: data,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    getEmployeeList: async (cancel = false) => {
        const response = await api.request({
            url: "digiapp/get_employee_list/" + localStorage.getItem('emp_id'),
            method: "GET",
            headers: { Authorization: `Bearer ${localStorage.getItem('auth')}` },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },


    getEmployeeDetails: async (data, cancel = false) => {

        const response = await api.request({
            url: "digiapp/emp_details",
            method: "POST",
            headers: { Authorization: `Bearer ${localStorage.getItem('auth')}` },
            data: data,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },



    postProject: async (data, cancel = false) => {
        const response = await api.request({
            url: "digiapp/project",
            method: "POST",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            data: { ...data, emp_id: localStorage.getItem('emp_id') },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;
    },
    getProject: async (cancel = false) => {
        const response = await api.request({
            url: "digiapp/project" + "?emp_id=" + localStorage.getItem('emp_id'),
            method: "GET",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;
    },
    putProject: async (id, data, cancel = false) => {
        const response = await api.request({
            url: "digiapp/project" + "/" + id,
            method: "PUT",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            data: { ...data, emp_id: Number(localStorage.getItem('emp_id')) },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;
    },
    //////////////
    /// TASK
    //////////////

    postTask: async (data, cancel = false) => {
        const response = await api.request({
            url: "digiapp/task",
            method: "POST",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            data: { ...data, emp_id: Number(localStorage.getItem('emp_id')) },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;

    },
    getTask: async (data, cancel = false) => {
        const response = await api.request({
            url: "digiapp/task_details",
            method: "GET",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            params: data,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;

    },
    putTask: async (id, data, cancel = false) => {
        const response = await api.request({
            url: "digiapp/task" + "/" + id,
            method: "PUT",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            data: data,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;
    },


    //////////////
    /// SUBTASK
    /////////////

    getSubTaskByTaskId: async (id, cancel = false) => {
        const response = await api.request({
            url: "digiapp/subtask_detail/" + id,
            method: "GET",
            headers: { Authorization: `Bearer ${localStorage.getItem('auth')}` },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;

    },

    postSubTask: async (data, cancel = false) => {
        const response = await api.request({
            url: "digiapp/task",
            method: "POST",
            headers: { Authorization: `Bearer ${localStorage.getItem('auth')}` },
            data: { ...data, emp_id: localStorage.getItem('emp_id') },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;
    },


    //////////////
    /// MEETING
    /////////////

    postMeeting: async (data, cancel = false) => {
        const response = await api.request({
            url: "digiapp/meeting",
            method: "POST",
            headers: { Authorization: `Bearer ${localStorage.getItem('auth')}` },
            data: { ...data, emp_id: localStorage.getItem('emp_id') },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;
    },

    getMeeting: async (data, cancel = false) => {
        const response = await api.request({
            url: "digiapp/meeting_details/" + localStorage.getItem('emp_id') + "?members=true",
            method: "GET",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;
    },

    //////////////
    /// TODO
    /////////////

    postTodo: async (data, cancel = false) => {
        const response = await api.request({
            url: "digiapp/todo",
            method: "POST",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            data: { ...data, emp_id: localStorage.getItem('emp_id') },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;

    },
    getTodo: async (cancel = false) => {
        const response = await api.request({
            url: "digiapp/todo",
            method: "GET",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;

    },
    putTodo: async (id, data, cancel = false) => {
        const response = await api.request({
            url: "digiapp/todo/" + id,
            method: "PUT",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            data: data,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;
    },


    /////////////
    /// LEAVE
    /////////////

    postLeave: async (data, cancel = false) => {
        const response = await api.request({
            url: "digiapp/leave",
            method: "POST",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            data: { ...data, emp_id: localStorage.getItem('emp_id') },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;

    },

    getLeave: async (cancel = false) => {
        const response = await api.request({
            url: "digiapp/leave" + "?members=true",
            method: "GET",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;

    },

    putLeave: async (data, cancel = false) => {
        const response = await api.request({
            url: "digiapp/leave",
            method: "PUT",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            data: data,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;
    },


    //////////////////
    ///// CALENDAR
    //////////////////

    getCalendar: async (params, cancel = false) => {
        const response = await api.request({
            url: "digiapp/calender/" + localStorage.getItem('emp_id'),
            method: "GET",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            params: params,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;
    },

    /////////////
    /// MESSAGES
    /////////////

    getChats: async (cancel = false) => {

        const emp_id = localStorage.getItem('emp_id');

        const response = await api.request({
            url: "digiapp/message_details/" + emp_id + '?members_list=true',
            method: "GET",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;

    },

    getChatMessages: async (id, cancel = false) => {

        const emp_id = localStorage.getItem('emp_id');

        const response = await api.request({
            url: "digiapp/message_details/" + emp_id + '?receive_id=' + id,
            method: "GET",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;

    },

    postChatMessage: async (data, cancel = false) => {

        const response = await api.request({
            url: "digiapp/message",
            method: "POST",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            data: data,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;

    },


    /////////////
    /// NOTIFICATION
    /////////////

    putNotification: async (id, data, cancel = false) => {
        const response = await api.request({
            url: "digiapp/notification/" + id,
            method: "PUT",
            headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('fbtoken'))}` },
            data: data,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });
        return response.data;
    },



}

const cancelApiObject = defineCancelApiObject(DashboardAPI);