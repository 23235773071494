import React,{useState,useEffect} from 'react'
import { FiFilter } from 'react-icons/fi'
import { AiOutlinePlus,AiOutlinePaperClip } from "react-icons/ai";
import {BsPencilFill} from "react-icons/bs";
import Modal from 'react-modal';
import { FaBell } from 'react-icons/fa';
import { AiFillMessage } from 'react-icons/ai';
import {IoIosArrowDropdown} from 'react-icons/io';
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import './Modal.css';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

interface Task {
  id: number;
  name: string;
  completed: boolean;
}

interface TodoItem {
  id: number;
  name: string;
  completed: boolean;
  reminder?: Date;
}
interface Meeting {
  name: string;
  time: string;
  date: string;
  link: string;
};
const data = [
  {
    name: "Pritam",
    Hours: 8,
  },
  {
    name: "Ali",
    Hours: 6,
  },
  {
    name: "XYZ",
    Hours: 4,
  },
  {
    name: "ABC",
    Hours: 2,
  },
 
];

const sortedData = data.sort((a, b) => a.Hours - b.Hours);

const Modal_ = ({ show, onClose, onSave, handleInputChange, projectName, date, workInfo,hours,status }:any) => {
    const modalClasses = show
      ? 'fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50'
      : 'hidden'; 
   
    return (
      <div className={modalClasses}>
        <div className="bg-white p-6 rounded-md ">
        <input
            type="text"
            name="projectName"
            onChange={handleInputChange}
            placeholder="Enter Project"
            className="mb-4 w-full p-2 border rounded"
          />
        <input
            type="text"
            name="person"
            onChange={handleInputChange}
            placeholder="Manager"
            className="mb-4 w-full p-2 border rounded"
          />
          <input
            type="text"
            name="date"
            value={date}
            onChange={handleInputChange}
            placeholder="Start Date"
            className="mb-4 w-full p-2 border rounded"
          />
           <input
            type="text"
            name="date"
            value={date}
            onChange={handleInputChange}
            placeholder="End Date"
            className="mb-4 w-full p-2 border rounded"
          />
          <input
            type="text"
            name="workInfo"
            value={workInfo}
            onChange={handleInputChange}
            placeholder="Project Description"
            className="mb-4 w-full p-2 border rounded"
          />
          <input
            type="number"
            name="hours"
            value={hours}
            onChange={handleInputChange}
            placeholder="Client"
            className="mb-4 w-full p-2 border rounded"
          />
           <select
          name="status"
          onChange={handleInputChange}
          className="mb-4 w-full p-2 border rounded text-gray-400"
          value={status}
        >
          <option value="" >Select Status</option>
          <option value="Assigned">Assigned</option>
          <option value="Finished">Finished</option>
          <option value="Pending">Pending</option>
          <option value="On Hold">On Hold</option>
          <option value="Urgent">High Priority</option>
        </select>
          <div className="flex justify-end">
            <button onClick={onClose} className="mr-2 px-4 py-2 border rounded">Cancel</button>
            <button onClick={onSave} className="px-4 py-2 bg-blue-500 text-white rounded">Save</button>
          </div>
        </div>
      </div>
    );
  };

const Admin=()=>{
    
    const [showModal, setShowModal] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [date, setDate] = useState('');
    const [workInfo, setWorkInfo] = useState('');
    const [status,setStatus] = useState('');
    const [hours,setHours] = useState('');
    const [tasks, setTasks] = useState([
        {
          projectName: 'Project A',
          date: '25 June',
          workInfo: 'Complete Report',
          status:'Assigned',
          subtasks: [
            {
              id: 1,
              title: 'Employee 1',
              description: 'Pritam',
            },
            {
              id: 2,
              title: 'Employee 2',
              description: 'Ali',
            },
          ] ?? [],
        },
        {
          projectName: 'Project B',
          date: '26 June',
          workInfo: 'Update Website',
          status:'Assigned',
        },
        
      ]);

      const [boldRows, setBoldRows] = useState<number[]>([]);
      const handleAddAsPriority = (index: number) => {
        const updatedTasks = [...tasks];
        const taskToMove = updatedTasks.splice(index, 1)[0];
        updatedTasks.unshift(taskToMove);
        setTasks(updatedTasks);
      
        const updatedBoldRows = [0, ...boldRows.map(row => row + 1)];
        setBoldRows(updatedBoldRows);
      };
  
    const handleInputChange = (e:any,index:number) => {
        const { name, value } = e.target;
    
        if (name === 'projectName') {
          setProjectName(value);
        } else if (name === 'date') {
          setDate(value);
        } else if (name === 'workInfo') {
          setWorkInfo(value);
        }
        else if (name === 'status') {
          setStatus(value);
        }
      };
  
      const handleAddTask = () => {
        console.log('Project Name:', projectName);
        console.log('Date:', date);
        console.log('Work Info:', workInfo);
        console.log('Status:', status);
        const newTask = {
            projectName: projectName,
            date: date,
            workInfo: workInfo,
            status: status,
          };
      
        setTasks([...tasks, newTask]);
        setProjectName('');
        setDate('');
        setWorkInfo('');
        setStatus('');
        setShowModal(false);
      };

      const openModal = () => {
        setShowModal(true);
      };
    
      const closeModal = () => {
        setShowModal(false);
      };
  

      const [taskList, setTaskList] = useState<Task[]>([]);
      const [newTaskName, setNewTaskName] = useState('');
      const [isModalOpen, setIsModalOpen] = useState(false);
    
      const addTask = () => {
        if (newTaskName.trim() === '') {
          return;
        }
    
        const newTask: Task = {
          id: Date.now(),
          name: newTaskName,
          completed: false,
        };
    
        setTaskList((prevTaskList) => [...prevTaskList, newTask]);
        setNewTaskName('');
        setIsModalOpen(false);
      };
    
      const handleTaskChange = (taskId: number) => {
        setTaskList((prevTaskList) => {
          const updatedTaskList = prevTaskList.map((task) =>
            task.id === taskId ? { ...task, completed: !task.completed } : task
          );
  
          const completedTaskIndex = updatedTaskList.findIndex((task) => task.id === taskId && task.completed);
          if (completedTaskIndex !== -1) {
            const completedTask = updatedTaskList.splice(completedTaskIndex, 1)[0];
            updatedTaskList.push(completedTask);
          }

          else {
            const uncheckedTaskIndex = updatedTaskList.findIndex((task) => task.id === taskId && !task.completed);
            if (uncheckedTaskIndex !== -1) {
              const uncheckedTask = updatedTaskList.splice(uncheckedTaskIndex, 1)[0];
              updatedTaskList.unshift(uncheckedTask);
            }
          }
    
          return updatedTaskList;
        });
      };
  
      const [todoList, setTodoList] = useState<TodoItem[]>([]);
      const [newTodoName, setNewTodoName] = useState('');
      const [isTodoModalOpen, setIsTodoModalOpen] = useState(false);
      const [currentTodoId, setCurrentTodoId] = useState<number | null>(null);
      const [newTodoReminder, setNewTodoReminder] = useState('');
      const handleTask_Change = (taskId: number) => {
        setTodoList((prevTodoList) => {
          const updatedTodoList = prevTodoList.map((todo) =>
            todo.id === taskId ? { ...todo, completed: !todo.completed } : todo
          );

          const completedTodoIndex = updatedTodoList.findIndex(
            (todo) => todo.id === taskId && todo.completed
          );
          if (completedTodoIndex !== -1) {
            const completedTodo = updatedTodoList.splice(completedTodoIndex, 1)[0];
            updatedTodoList.push(completedTodo);
          }

          return updatedTodoList;
        });
      };

      const handleAddTodo = () => {
        const newTodo = {
          id: todoList.length + 1,
          name: newTodoName,
          completed: false,
          reminder: new Date(newTodoReminder)
        };
        setTodoList([...todoList, newTodo]);
        setNewTodoName('');
        setNewTodoReminder('');
        setIsTodoModalOpen(false);
      };

      const [showNotification, setShowNotification] = useState(false);
      const [notificationMessage, setNotificationMessage] = useState('');
      useEffect(() => {
        const checkReminder = () => {
          const currentTime = new Date().getTime();
          todoList.forEach((todo) => {
            if (todo.reminder && todo.reminder.getTime() <= currentTime) {
              // Display the notification
              setShowNotification(true);
              setNotificationMessage(`Reminder for task '${todo.name}'`);
    

    
              // Update the todo item to remove the reminder
              setTodoList((prevTodoList) =>
                prevTodoList.map((t) =>
                  t.id === todo.id ? { ...t, reminder: undefined } : t
                )
              );
            }
          });
        };
    
        const intervalId = setInterval(checkReminder, 1000); // Check every second
    
        return () => {
          clearInterval(intervalId); // Clean up the interval when the component unmounts
        };
      }, [todoList]);
    
  const [isMeetingModalOpen, setIsMeetingModalOpen] = useState(false);
  const [newMeetingName, setNewMeetingName] = useState('');
  const [newMeetingTime, setNewMeetingTime] = useState('');
  const [newMeetingDate, setNewMeetingDate] = useState('');
  const [newMeetingLink, setNewMeetingLink] = useState('');
  const [scheduledMeeting, setScheduledMeeting] = useState<Meeting | null>(null);

  const openMeetingModal = () => {
    setIsMeetingModalOpen(true);
  };

  const closeMeetingModal = () => {
    setIsMeetingModalOpen(false);
  };

  const scheduleMeeting = () => {
    setNewMeetingName('');
    setNewMeetingTime('');
    setNewMeetingDate('');
    setNewMeetingLink('');
    setIsMeetingModalOpen(false);
  };

  const handleMeetButtonClick = () => {
    if (newMeetingName && newMeetingTime && newMeetingDate && newMeetingLink) {
      setScheduledMeeting({
        name: newMeetingName,
        time: newMeetingTime,
        date: newMeetingDate,
        link: newMeetingLink
      });
    }
    setIsMeetingModalOpen(false);
  };

  const [isButtonBold, setIsButtonBold] = useState(false);

  const handleButtonClick = () => {
    setIsButtonBold(true);
  };
  
  const [editIndex, setEditIndex] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const items: MenuProps["items"] = [
    {
      label: (
        <p>
          Edit Task
        </p>
      ),
      key: "0",
    },
    {
      label: (
        <p>
          Change Status
        </p>
      ),
      key: "1",
    },
    {
      label: (
        <p>
          Add for Verification
        </p>
      ),
      key: "2",
    },
  ];
  const [showSubtasks, setShowSubtasks] = useState<number[]>([]);
const handleToggleSubtasks = (index: number) => {
  const updatedShowSubtasks = [...showSubtasks];
  if (updatedShowSubtasks.includes(index)) {
    updatedShowSubtasks.splice(updatedShowSubtasks.indexOf(index), 1);
  } else {
    updatedShowSubtasks.push(index);
  }
  setShowSubtasks(updatedShowSubtasks);
};



  return (
    <div>
    <div className='h-[100%]'>
        <div className='bg-neutral-100 rounded-lg min-h-[50px] p-4 overflow-auto'>
                    <div className="flex justify-between items-center">
                        <h2 className="text-xl font-bold">PROJECT</h2>
                        <button className="ml-[5px]" >
                          <FiFilter/>
                        </button>
                        <div className="flex items-center space-x-2 ml-auto">
                        <button className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded-lg" onClick={openModal}>
                            <AiOutlinePlus/>
                        </button>
                        </div>
                        <Modal_
                            show={showModal}
                            onClose={closeModal}
                            onSave={handleAddTask}
                            handleInputChange={handleInputChange}
                            projectName={projectName}
                            date={date}
                            workInfo={workInfo}
                        />
                    </div>
                    <table className="mt-4 w-full">
                    <tbody>
                    <tr className="border-gray-400;">
                          
                          <td className="font-bold">Project </td>
                          <td className="font-bold">Manager </td>
                          <td className="font-bold">Leader </td>
                          <td className="font-bold">Client </td>
                          <td className="font-bold">Start Date</td>
                          <td className="font-bold">End Date</td>
                          <td className="font-bold">Project Descp</td>
                          <td className="font-bold text-center">Status</td>
                         
                    </tr>
                    {tasks
                    .sort((a, b) => (a.status === "Urgent" ? -1 : b.status === "Urgent" ? 1 : 0))
                    .map((task, index) => (
                      <React.Fragment key={index}>
                        <tr
                          key={index}
                          className={`border-gray-400 ${
                            boldRows.includes(index) 
                          }`}
                        >
                        <td className="py-2">{task.projectName}</td>
                        <td className="py-2">{task.projectName}</td>
                        <td className="py-2">{task.projectName}</td>
                        <td className="py-2">{task.projectName}</td>
                        <td className="py-2">{task.projectName}</td>
                        <td className="py-2">{task.date}</td>
                        <td className="py-2">{task.workInfo}</td>
                        <td   className={`py-2 text-center${
                            task.status === "Urgent" ? "font-bold bg-red-500 rounded-lg text-center" : ""
                          }`} style={{ color: task.status === "Assigned"? "black" :task.status === "Urgent"? "white" : task.status === "On Hold" ? "#8B8000" : task.status === "Pending" ? "red" : "green" }}>
                        {task.status}
                      </td>
                          <td className="py-2">
                            <Dropdown menu={{ items }} trigger={["click"]}>
                              <button /*onClick={() => handleEditTask(index)}*/ className='ml-[50px]'><BsPencilFill/></button>
                           </Dropdown>
                            <button /*onClick={() => handleAddAsPriority(index)}*/ className='ml-[20px]'><AiFillMessage/></button>
                            <button  onClick={() =>  handleToggleSubtasks(index)} className='ml-[20px]'><IoIosArrowDropdown/></button>
                          </td>
                        </tr>
                        {showSubtasks.includes(index) && task.subtasks && (
                          <tr>
                            <td></td>
                            <td className="py-2" colSpan={6} style={{ wordWrap: 'break-word', maxWidth: '400px' }}>
                              <ul>
                                {task.subtasks.map((subtask) => (
                                  <li key={subtask.id} >
                                  <strong> {subtask.title}:</strong> {subtask.description}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                    
                    </tbody>
                    </table>
        </div>
        
    </div>
</div>
  )
}



export default Admin;