import { useEffect, useState } from "react";

import { Table, Radio } from "antd";
import Modal from "../../../Components/Modal";
// import CustomPagination from "../../../components/Pagination/Pagination";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../routes/RouterConfig";
import Input from "../../../Components/Input/Input";
import Select from "../../../Components/Select/Select";

import { DatePicker, Space } from "antd";
import { BsArrowLeft, BsBack, BsThreeDots } from "react-icons/bs";
import { DashboardAPI } from "../../../apis/dashboardapi";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

const { RangePicker } = DatePicker;

const SubTask = () => {
  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => setIsOpen(false);
  const handleOpenModal = () => setIsOpen(true);

  const { state } = useLocation();
  const [task, setTask] = useState<any>({
    ...state.task,
  });

  const [count, setCount] = useState({});
  const navigate = useNavigate();
  const [data, setData] = useState([
    {
      key: "1",
      name: "Subtask 1",
      subtask_count: 4,
      subtask: [],
      employee: "Employee 1",
      date: "12/12/2021",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla e",
    },
  ]);

  const [employees, setEmployees] = useState<any[]>([]);

  // state is any or unknown

  const getEmployees = async () => {
    try {
      setLoading(true);
      const res2 = await DashboardAPI.getEmployeeList();
      if (res2.success) {
        const empList = res2.data.values;
        const empTypes = Object.keys(empList);

        const empArr: any = [];

        empTypes.forEach((type) => {
          empList[type].forEach((emp: any) => {
            if (emp?.emp_id === localStorage.getItem("emp_id")) return;
            if (type === "clients") return;
            empArr.push({
              label: emp?.employee_name,
              value: emp?.emp_id,
              type: type,
            });
          });
        });

        setEmployees(empArr);
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    try {
      const res1 = await DashboardAPI.getSubTaskByTaskId(task.task_id);

      if (res1.success) {
        console.log(res1.data.values);
        setData(res1.data.values);
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    }
  };

  const columns = [
    {
      title: "Subtask Name",
      dataIndex: "task_title",
      key: "task_title",
      render: (index: any, val: any) => {
        return (
          <p
            className="cursor-pointer"
            onClick={(e) => {
              console.log(index, val);
              //   navigate(ROUTES.Doctor.AppointmentHistory + "/" + val.id);
            }}
          >
            {index}
          </p>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "task_desc",
      key: "task_desc",
    },
    {
      title: "Assigned To",
      dataIndex: "assigned_id",
      key: "assigned_id",
      render: (index: any, val: any) => {
        return (
          <p className="cursor-pointer">
            {employees?.find((item: any) => item.value === index)?.label}
          </p>
        );
      },
    },
    {
      title: "Date",
      render: (_: any, record: any) => {
        return (
          <>
            <p>
              {moment(record.start_date)?.format("DD MMM YY")} -{" "}
              {moment(record.end_date)?.format("DD MMM YY")}
            </p>
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => {
        console.log(record);
        return (
          <>
            {" "}
            <div className="flex gap-[10px] flex-row items-center">
              <button
                className="text-white py-[5px] h-[100%] px-[10px]"
                title="option"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <BsThreeDots color="black" />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  const [value, setValue] = useState(1);
  const onChange = (e: any) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  useEffect(() => {
    getData();
    getEmployees();
  }, []);

  const [formData, setFormData] = useState({
    subtask_title: "",
    task_id: task.task_id,
    subtask_desc: "",
    start_date: "",
    end_date: "",
    status: "",
    Sub_assigned_id: [],
  });

  const addSubtask = async () => {
    try {
      setLoading(true);
      const res = await DashboardAPI.postSubTask({
        task_title: formData?.subtask_title,
        task_desc: formData?.subtask_desc,
        parent_task_id: task.task_id,
        assigned_id: formData.Sub_assigned_id,
        project_id: task?.project_id,
        start_date: new Date(formData.start_date)?.toISOString(),
        end_date: new Date(formData.end_date)?.toISOString(),
      });
      if (res.success) {
        toast.success("Subtask Added Successfully");
        handleCloseModal();
        getData();
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const [access, setAccess] = useState<any>(localStorage.getItem("access_id"));

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("create")) {
      handleOpenModal();
    }
  }, [searchParams]);

  return (
    <div className="flex flex-col">
      <ToastContainer />
      <Modal
        width={"80%"}
        className="bg-neutral-1"
        isOpen={isOpen}
        handleSubmit={addSubtask}
        handleCancel={handleCloseModal}
      >
        <p className="text-lg font-semibold ">Add Subtask</p>
        <div className="grid grid-cols-2 gap-3 mt-[14px]">
          <div className="col-span-2">
            <Input
              label="Subtask Name"
              placeholder="Enter Subtask Name"
              value={formData.subtask_title}
              handleChange={(e: any) => {
                setFormData({ ...formData, subtask_title: e.target.value });
              }}
            />
          </div>
          <div className="col-span-2">
            <label
              className={"text-[#333333] opacity-70 font-medium text-[16px] "}
            >
              Description
            </label>
            <textarea
              placeholder="Enter Description"
              className="p-[8px] border-[1px] mt-[5px] border-gray-200 h-[100px] w-[100%] rounded-[8px]"
              onChange={(e) => {
                setFormData({ ...formData, subtask_desc: e.target.value });
              }}
            />
          </div>

          <div className="col-span-1 flex flex-col gap-[5px]">
            <label
              className={"text-[#333333] opacity-70 font-medium text-[16px] "}
            >
              Date Range
            </label>
            <RangePicker
              className="h-[40px]"
              onChange={(e, k) => {
                setFormData({
                  ...formData,
                  start_date: k[0],
                  end_date: k[1],
                });
              }}
            />
          </div>

          <Select
            className="w-full"
            label="Assigned to"
            options={[...employees]}
            placeholder="Select Employee"
            value={formData.Sub_assigned_id || null}
            handleChange={(e: any) => {
              setFormData({ ...formData, Sub_assigned_id: e });
            }}
          />

          <Select
            className="w-full"
            label="Status"
            options={[
              { label: "Pending", value: "pending" },
              { label: "In Progress", value: "progress" },
              { label: "Completed", value: "completed" },
              { label: "Update", value: "update" },
            ]}x
            placeholder="Select Status"
            value={formData.status || null}
            handleChange={(e: any) => {
              setFormData({ ...formData, status: e });
            }}
          />
        </div>
      </Modal>

      <div className="flex mb-[30px]">
        <p
          className="flex gap-[10px] items-center justify-center hover:underline text-gray-400 hover:text-gray-700 cursor-pointer"
          onClick={() => {
            navigate(ROUTES.TASK.root);
          }}
        >
          <BsArrowLeft /> Task
        </p>
      </div>

      <div className="flex justify-between items-center">
        <p className="text-lg front-semibold ">Sub Task</p>

        <div className="flex gap-[10px] flex-row items-center">
          {access < 3 && (
            <button
              className="bg-black py-[8px] px-[20px] rounded-[4px] text-white  "
              onClick={handleOpenModal}
            >
              {" "}
              + Sub Task
            </button>
          )}
        </div>
      </div>

      <div className="flex p-[30px] rounded-[10px] border-[1px] border-gray-200 flex-col mt-[30px]">
        <h2 className="text-lg font-[600] ">{task?.task_title}</h2>
        <h2 className="">{task?.task_desc}</h2>
      </div>

      <div className="mt-[30px]">
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
    </div>
  );
};

export default SubTask;
