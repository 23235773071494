import React, { useEffect, useState } from "react";
import {
  PhoneFilled,
  VideoCameraFilled,
  InfoCircleFilled,
  CheckOutlined,
} from "@ant-design/icons";

import { RiSendPlaneFill } from "react-icons/ri";
// import Button from '../../../components/Button/Button'
// import { ChatAPI } from '../../../apis/chatApi'
import { ToastContainer, toast } from "react-toastify";
// import Nodata from './../../../assets/images/nodata.svg'
import Loader from "../../../Components/Loader";
import { message } from "antd";
import { DashboardAPI } from "../../../apis/dashboardapi";
import { BiEdit } from "react-icons/bi";

import Select from "../../../Components/Select/Select";
import Modal from "../../../Components/Modal";

const RenderChat = ({ data, getPerticularChat }: any) => {
  return (
    <div
      onClick={() => {
        getPerticularChat(data);
      }}
      className="flex bg-white border-[1px] border-neutral-3 cursor-pointer justify-between items-center bg-gray-100 rounded-[8px] py-[10px] px-[5px] hover:shadow-md hover:border-primary-3"
    >
      <div className="flex items-center gap-2">
        <div className="">
          <div className="w-[40px] h-[40px] rounded-[50%] border-[1px] bg-blue-200 flex items-center justify-center">
            <p className="uppercase">{data?.name?.substring(0, 2)}</p>
          </div>
        </div>
        <div className=" text-Medium+/Label/Medium text-gray-1000">
          <p className="capitalize">{data?.name}</p>
        </div>
      </div>
    </div>
  );
};

function Chat() {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState<any>([]);
  const [selectedProfile, setSelectedProfile] = useState<any>();

  const [inputMessage, setInputMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [employees, setEmployees] = useState([]);

  const [access, setAccess] = useState<any>(
    localStorage.getItem("access_id") || null
  );

  const getData = async () => {
    try {
      setLoading(true);
      const res = await DashboardAPI.getEmployeeList();
      if (res.success) {
        const empList = res.data.values;
        const empTypes = Object.keys(empList);

        const empArr: any = [];

        empTypes.forEach((type) => {
          empList[type].forEach((emp: any) => {
            if (emp?.emp_id === localStorage.getItem("emp_id")) return;
            if (type === "clients" && access > 2) return;
            empArr.push({
              label: emp?.employee_name,
              value: emp?.emp_id,
              type: type,
            });
          });
        });

        setEmployees(empArr);
      }
    } catch (err: any) {
      toast.error(err?.message || "Failed to fetch Chats");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getChatData = async () => {
    try {
      setLoading(true);

      const res = await DashboardAPI.getChats();

      if (res.success) {
        setChats(res.data.values);
      }
    } catch (err: any) {
    } finally {
    }
  };

  useEffect(() => {
    getChatData();
  }, []);

  const getPerticularChat = async (d: any) => {
    setLoading(true);
    try {
      const res = await DashboardAPI.getChatMessages(d?.emp_id);

      if (res.success) {
        const messages = [
          ...res.data.values.receive_messaage?.map((i: any) => ({
            ...i,
            type: "receive",
          })),
          ...res.data.values.send_messaage?.map((i: any) => ({
            ...i,
            type: "send",
          })),
        ]?.sort((a: any, b: any) => {
          return (
            new Date(a?.message_time)?.getTime() -
            new Date(b?.message_time)?.getTime()
          );
        });

        setSelectedChat(messages);
        setSelectedProfile(d);
      }
    } catch (err) {
      toast.error("Failed to fetch this chat");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitMessage = async () => {
    if (!message) return;

    if (!selectedProfile) return toast.error("Please select a profile");

    const obj = {
      send_id: localStorage.getItem("emp_id"),
      receive_id: selectedProfile?.emp_id,
      message_desc: inputMessage,
    };
    try {
      const res = await DashboardAPI.postChatMessage(obj);
      if (res.success) {
        toast.success("Message Sent Successfully");
        getPerticularChat(selectedProfile);
        setInputMessage("");
        handleCloseModal();
      }
    } catch (err: any) {
      toast.error(err?.message || "Failed to send message");
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => setIsOpen(false);
  const handleOpenModal = () => setIsOpen(true);

  return (
    <div className="flex gap-[10px] p-[20px] min-h-[95vh]">
      {loading ? <Loader /> : null}
      <ToastContainer />
      <div className="w-[35%] p-[10px] shadow-md border-[1px] border-primary-2 bg-gray-100 rounded-[10px] max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center flex-row mb-[30px]">
          <h2 className=" w-[fit-content] text-Medium+/Paragraph/Large">
            Messages
          </h2>
          <button
            className="cursor-pointer"
            onClick={() => {
              handleOpenModal();
            }}
          >
            <BiEdit className="text-[20px] text-primary-6 opacity-[0.8] ml-[10px]" />
          </button>
        </div>
        <div className="flex flex-col gap-[10px]">
          {chats.map((e, i) => (
            <RenderChat data={e} getPerticularChat={getPerticularChat} />
          ))}
        </div>
      </div>

      {selectedChat && selectedChat?.length != 0 ? (
        <div className="w-[65%] bg-gray-100 overflow-hidden shadow-md border-[1px] border-primary-2 rounded-[10px] border-[1px] h-[80vh] max-h-[80vh] relative flex flex-col">
          <div className="flex bg-white overflow-hidden items-center gap-[15px] border-b-[1px] border-primary-2 p-[10px] h-[80px]">
            <div className="w-[60px] h-[60px] rounded-[50%] border-[1px] bg-blue-200 flex items-center justify-center">
              <p className="uppercase">
                {selectedProfile?.name?.substring(0, 2)}
              </p>
            </div>
            <div className="flex flex-col">
              <h6 className="capitalize">{selectedProfile?.name}</h6>
            </div>
            <div className="flex gap-[20px] ml-auto">
              <PhoneFilled className="text-[22px] text-primary-6 opacity-[0.8]" />
              <InfoCircleFilled className="text-[22px] text-primary-6 opacity-[0.8]" />
            </div>
          </div>

          <div className="flex flex-col gap-3 overflow-y-scroll px-4 grow pb-[70px] pt-[50px]">
            {selectedChat?.map((i: any, key: number) => (
              <div
                className={` flex  ${i?.type == "send" ? "justify-end" : ""}`}
              >
                <div className="max-w-[60%] border bg-white shadow p-2 rounded-lg px-4">
                  <h5 className="text-primary-6">
                    {i?.type == "send" ? "You" : selectedProfile?.name}
                  </h5>
                  {i?.message_desc}
                </div>
              </div>
            ))}
          </div>

          <div className=" p-1 pb-2 mt-4 absolute bottom-0 left-0 right-0 bg-gray-100">
            <div className="border-[1px] border-gray-200 bg-white rounded-[8px] flex px-[10px] shadow-md p-1">
              <input
                value={inputMessage}
                onChange={(e) => {
                  setInputMessage(e.target.value);
                }}
                type="text"
                className="w-[80%] bg-transparent outline-none"
                placeholder="Type a message"
              />
              <div className="w-[20%] ">
                <button
                  onClick={() => handleSubmitMessage()}
                  className="bg-[var(--primary)] flex gap-[10px] justify-center p-[10px] align text-gray-100 text-Medium+/Label/Small-Strong rounded-[4px] ml-auto"
                >
                  <RiSendPlaneFill className="text-[20px] text-white" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-[65%] bg-gray-100 shadow-md border-[1px] border-primary-2 rounded-[10px] border-[1px] h-[80vh] flex justify-center items-center text-center">
          <div>
            {/* <img src={Nodata} className="w-[250px]" alt="" /> */}
            <h6 className="text-primary-6 mt-3 text-lg">No Chat Seleted</h6>
            <h6 className="text-neutral-7">
              Seleted any one of the doctor to chat with
            </h6>
          </div>
        </div>
      )}

      <Modal
        width={"80%"}
        className="bg-neutral-1"
        isOpen={isOpen}
        handleSubmit={() => handleSubmitMessage()}
        handleCancel={handleCloseModal}
      >
        <p className="text-lg font-semibold ">New Message</p>
        <div className="grid grid-cols-2 gap-3 mt-[14px]">
          <Select
            className="w-full"
            label="Select Employee"
            options={[...employees]}
            placeholder="Select Employee"
            // value={selectedProfile}
            handleChange={(e: any) => {
              const emp: any = employees?.find((i: any) => i?.value == e);
              setSelectedProfile({
                emp_id: emp?.value,
                name: emp?.label,
              });
            }}
          />

          <div className="col-span-2">
            <label
              className={"text-[#333333] opacity-70 font-medium text-[16px] "}
            >
              Message
            </label>
            <textarea
              placeholder="Enter Description"
              className="p-[8px] border-[1px] mt-[5px] border-gray-200 h-[100px] w-[100%] rounded-[8px]"
              onChange={(e) => {
                setInputMessage(e.target.value);
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Chat;
