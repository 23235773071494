import { useEffect, useState } from "react";

import { Table, Radio } from "antd";
import Modal from "../../../Components/Modal";
// import CustomPagination from "../../../components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes/RouterConfig";
import Input from "../../../Components/Input/Input";
import Select from "../../../Components/Select/Select";

import { DatePicker, Space } from "antd";
import { DashboardAPI } from "../../../apis/dashboardapi";
import { ToastContainer, toast } from "react-toastify";
import { BiEdit, BiSolidEditAlt } from "react-icons/bi";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const Project = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => setIsOpen(false);
  const handleOpenModal = () => setIsOpen(true);

  const [loading, setLoading] = useState(false);

  const [selectedData, setSelectedData] = useState<any>({});
  const [edit, setEdit] = useState(false);

  const [count, setCount] = useState({});
  const navigate = useNavigate();

  const [data, setData] = useState<any>([]);
  const [employees, setEmployees] = useState<any>([]);

  const getData = async () => {
    try {
      const [res1, res2] = await Promise.all([
        DashboardAPI.getProject(),
        DashboardAPI.getEmployeeList(),
      ]);

      if (res1.success) {
        setData(res1.data.values);
      }

      if (res2.success) {
        const empList = res2.data.values;
        const empTypes = Object.keys(empList);

        const empArr: any = [];

        empTypes.forEach((type) => {
          empList[type].forEach((emp: any) => {
            empArr.push({
              label: emp?.employee_name,
              value: emp?.emp_id,
              type: type,
            });
          });
        });

        setEmployees(empArr);
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Project Name",
      dataIndex: "project_name",
      key: "project_name",
      render: (index: any, val: any) => {
        return (
          <p
            className="cursor-pointer"
            onClick={(e) => {
              navigate(ROUTES.TASK.root, {
                state: {
                  project_id: val?.project_id,
                },
              });
            }}
          >
            {index}
          </p>
        );
      },
    },
    {
      title: "Client",
      dataIndex: "project_client",
      key: "project_client",
      render: (index: any, val: any) => {
        return (
          <p className="cursor-pointer">
            {employees?.find((item: any) => item.value === index)?.label}
          </p>
        );
      },
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    // },

    {
      title: "Manager",
      dataIndex: "project_manager",
      key: "project_manager",
      render: (index: any, val: any) => {
        return (
          <p className="cursor-pointer">
            {employees?.find((item: any) => item.value === index)?.label}
          </p>
        );
      },
    },
    {
      title: "Leader",
      dataIndex: "project_leader",
      key: "project_leader",
      render: (index: any, val: any) => {
        return (
          <p className="cursor-pointer">
            {employees?.find((item: any) => item.value === index)?.label}
          </p>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (index: any, val: any) => {
        return <p className="cursor-pointer">{index}</p>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => {
        console.log(record);
        return access < 3 ? (
          <>
            {" "}
            <div className="flex gap-[10px] flex-row items-center">
              <button
                className="text-white py-[5px] h-[100%] px-[10px]"
                title="option"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedData(record);
                  setFormData({
                    ...record,
                  });
                  handleOpenModal();
                  setEdit(true);
                }}
              >
                <BiSolidEditAlt color="black" size={20} />
              </button>
            </div>
          </>
        ) : (
          "-"
        );
      },
    },
  ];

  const addProject = async () => {
    if (!formData?.project_client) {
      toast.error("Please select client");
      return;
    } else if (!formData.project_name) {
      toast.error("Please enter project name");
      return;
    } else if (!formData.start_date) {
      toast.error("Please enter start date");
      return;
    } else if (!formData.end_date) {
      toast.error("Please enter end date");
      return;
    } else if (!formData.project_manager) {
      toast.error("Please select project manager");
      return;
    }

    try {
      const res = await DashboardAPI.postProject({
        ...formData,
        project_members: formData.project_members,
      });
      if (res.success) {
        toast.success("Project Added Successfully");
        handleCloseModal();
        getData();
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const updateProject = async () => {
    try {
      setLoading(true);
      const res = await DashboardAPI.putProject(selectedData?.project_id, {
        ...formData,
      });
      if (res.success) {
        toast.success(res.message);
        handleCloseModal();
        getData();
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [formData, setFormData] = useState({
    project_client: "",
    project_name: "",
    start_date: "",
    end_date: "",
    project_manager: "",
    project_leader: "",
    project_members: [],
    description: "",
    status: "",
  });

  const [access, setAccess] = useState<any>(
    localStorage.getItem("access_id") || null
  );

  return (
    <div className="flex flex-col">
      <ToastContainer />
      <Modal
        width={"80%"}
        className="bg-neutral-1"
        isOpen={isOpen}
        handleSubmit={edit ? updateProject : addProject}
        handleCancel={handleCloseModal}
      >
        <p className="text-lg font-semibold ">Add Project</p>
        <div className="grid grid-cols-2 gap-3 mt-[14px]">
          <div className="col-span-2">
            <Select
              className="w-full"
              label="Client"
              options={[
                ...employees?.filter((item: any) => item.type === "clients"),
              ]}
              placeholder="Select Client"
              value={formData.project_client || null}
              handleChange={(e: any) => {
                setFormData({ ...formData, project_client: e });
              }}
            />
          </div>

          <div className="col-span-2">
            <Input
              label="Project Name"
              placeholder="Enter Project Name"
              value={formData.project_name || ""}
              handleChange={(e: any) => {
                setFormData({ ...formData, project_name: e.target.value });
              }}
            />
          </div>
          <div className="col-span-2">
            <label
              className={"text-[#333333] opacity-70 font-medium text-[16px] "}
            >
              Description
            </label>
            <textarea
              onChange={(e) => {
                setFormData({ ...formData, description: e.target.value });
              }}
              value={formData.description || ""}
              placeholder="Enter Description"
              className="p-[8px] border-[1px] mt-[5px] border-gray-200 h-[100px] w-[100%] rounded-[8px]"
            />
          </div>

          <div className="col-span-1 flex flex-col gap-[5px]">
            <label
              className={"text-[#333333] opacity-70 font-medium text-[16px] "}
            >
              Date Range
            </label>
            <RangePicker
              className="h-[40px]"
              value={
                formData.start_date
                  ? [dayjs(formData.start_date), dayjs(formData.end_date)]
                  : null
              }
              onChange={(e, k) => {
                setFormData({
                  ...formData,
                  start_date: k[0],
                  end_date: k[1],
                });
              }}
            />
          </div>

          <Select
            className="w-full"
            label="Employee"
            multiple
            options={[
              ...employees?.filter((item: any) => item.type !== "clients"),
            ]}
            placeholder="Select Employee"
            value={formData.project_members || null}
            handleChange={(e: any) => {
              setFormData({ ...formData, project_members: e });
            }}
          />
          <Select
            className="w-full"
            label="Project Leader"
            options={[
              ...employees?.filter((item: any) => item.type !== "clients"),
            ]}
            placeholder="Select Leader"
            value={formData.project_leader || null}
            handleChange={(e: any) => {
              setFormData({ ...formData, project_leader: e });
            }}
          />
          <Select
            className="w-full"
            label="Project Manager"
            options={[
              ...employees?.filter((item: any) => item.type !== "clients"),
            ]}
            placeholder="Select Manager"
            value={formData.project_manager || null}
            handleChange={(e: any) => {
              setFormData({ ...formData, project_manager: e });
            }}
          />

          <Select
            className="w-full"
            label="Status"
            options={[
              { label: "Pending", value: "pending" },
              { label: "In Progress", value: "progress" },
              { label: "Completed", value: "completed" },
              { label: "Update", value: "update" },
            ]}
            placeholder="Select Status"
            value={formData.status || null}
            handleChange={(e: any) => {
              setFormData({ ...formData, status: e });
            }}
          />
        </div>
      </Modal>

      <div className="flex justify-between items-center">
        <p className="text-lg front-semibold">Projects</p>

        <div className="flex gap-[10px] flex-row items-center">
          <input
            type="text"
            placeholder="Search"
            className="border-[1px] border-[#E9E9E9] rounded-[4px] py-[5px] h-[100%] px-[10px] h-[40px]"
          />
          {access < 3 && (
            <button
              className="bg-black py-[8px] px-[20px] rounded-[4px] text-white  "
              onClick={handleOpenModal}
            >
              {" "}
              + Project
            </button>
          )}
        </div>
      </div>

      <div className="grid grid-cols-3 mt-[30px]">
        <Select
          className="w-full"
          label="Select Client"
          options={[
            ...employees?.filter((item: any) => item.type === "clients"),
          ]}
          placeholder="Select Client"
          // value={filters.project || null}
          handleChange={(e: any) => {
            // setFilters({ ...filters, project: e });
          }}
        />
      </div>

      <div className="mt-[30px]">
        <Table columns={columns} dataSource={data} />
        {/* <CustomPagination currentPage={1} /> */}
      </div>
    </div>
  );
};

export default Project;
