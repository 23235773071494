import { useEffect, useState } from "react";

import { Table, Radio } from "antd";
import Modal from "../../../Components/Modal";
// import CustomPagination from "../../../components/Pagination/Pagination";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../routes/RouterConfig";
import Input from "../../../Components/Input/Input";
import Select from "../../../Components/Select/Select";

import { DatePicker, Space } from "antd";
import { BsArrowDown, BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { DashboardAPI } from "../../../apis/dashboardapi";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
// import { FaAngleUp } from "react-icons/fa6";

import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const Task = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => setIsOpen(false);
  const handleOpenModal = () => setIsOpen(true);

  const { state } = useLocation();

  const [filters, setFilters] = useState({
    project: state?.project_id || null,
  });

  const [count, setCount] = useState({});
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);

  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState<any[]>([]);
  const [projects, setProjects] = useState([]);

  const getTasks = async () => {
    try {
      setLoading(true);

      const obj: any = {
        emp_id: localStorage.getItem("emp_id") || null,
      };

      if (filters.project) {
        obj["project_id"] = filters.project;
      }

      const res = await DashboardAPI.getTask(obj);

      if (res.success) {
        setData(
          res.data.values.task_details?.map((item: any) => {
            return {
              ...item,
              state: false,
              subtasks: [],
            };
          })
        );
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    try {
      const [res2, res3] = await Promise.all([
        DashboardAPI.getEmployeeList(),
        DashboardAPI.getProject(),
      ]);

      // if (res1.success) {
      //   setData(res1.data.values.task_details);
      // }

      if (res2.success) {
        const empList = res2.data.values;
        const empTypes = Object.keys(empList);

        const empArr: any = [];

        empTypes.forEach((type) => {
          empList[type].forEach((emp: any) => {
            if (emp?.emp_id === localStorage.getItem("emp_id")) return;
            if (type === "clients") return;
            empArr.push({
              label: emp?.employee_name,
              value: emp?.emp_id,
              type: type,
            });
          });
        });

        setEmployees(empArr);
      }

      if (res3.success) {
        setProjects(
          res3.data.values?.map((item: any) => ({
            label: item?.project_name,
            value: item?.project_id,
          }))
        );
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const addTask = async () => {
    if (!formData.task_title) {
      toast.error("Please enter project name");
      return;
    } else if (!formData.start_date) {
      toast.error("Please enter start date");
      return;
    } else if (!formData.end_date) {
      toast.error("Please enter end date");
      return;
    } else if (!formData.task_desc) {
      toast.error("Please select project manager");
      return;
    }

    try {
      const res = await DashboardAPI.postTask({
        ...formData,
        assigned_id: formData.assigned_id,
        subtask_id: [],
        start_date: new Date(formData.start_date).toISOString(),
        end_date: new Date(formData.end_date).toISOString(),
      });
      if (res.success) {
        toast.success("Task Added Successfully");
        getData();
        handleCloseModal();
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const updateTask = async () => {
    try {
      setLoading(true);
      const res = await DashboardAPI.putTask(selectedData?.task_id, {
        ...formData,
      });
      if (res.success) {
        toast.success(res.message);
        handleCloseModal();
        getData();
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Task Name",
      dataIndex: "task_title",
      key: "task_title",
      render: (index: any, val: any) => {
        return (
          <p
            className="cursor-pointer"
            onClick={(e) => {
              console.log(index, val);
              //   navigate(ROUTES.Doctor.AppointmentHistory + "/" + val.id);
            }}
          >
            {index}
          </p>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "task_desc",
      key: "task_desc",
    },
    {
      title: "Subtasks",
      dataIndex: "subtask_id",
      key: "subtask_id",
      render: (index: any, val: any) => {
        return (
          <p
            className="cursor-pointer hover:underline"
            onClick={(e) => {
              e.preventDefault();
              navigate(ROUTES.TASK.subtask, {
                state: {
                  task: val,
                },
              });
            }}
          >
            {index?.length}
          </p>
        );
      },
    },
    {
      title: "Employee",
      dataIndex: "assigned_id",
      key: "assigned_id",
      render: (index: any, val: any) => {
        return (
          <p className="cursor-pointer">
            {employees?.find((item: any) => item.value == index)?.label}
          </p>
        );
      },
    },
    {
      title: "Date",
      key: "date",
      render: (index: any, val: any) => {
        return (
          <p className="cursor-pointer">
            {moment(val.start_date).format("DD MMM YYYY")} -{" "}
            {moment(val.end_date).format("DD MMM YYYY")}
          </p>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => {
        console.log(record);
        return (
          <>
            {" "}
            <div className="flex gap-[10px] flex-row items-center">
              <button
                className="text-white py-[5px] h-[100%] px-[10px]"
                title="option"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <BsThreeDots color="black" />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getTasks();
  }, [filters]);

  const [formData, setFormData] = useState({
    task_title: "",
    emp_id: "",
    assigned_id: "",
    project_id: "",
    task_desc: "",
    start_date: "",
    end_date: "",
    status: "",
  });

  const getSubtaskData = async (id: any) => {
    try {
      const res1 = await DashboardAPI.getSubTaskByTaskId(id);

      if (res1.success) {
        console.log(res1.data.values);
        const subtasks = res1.data.values;

        setData(
          data?.map((item: any) => {
            if (item.task_id === id) {
              return {
                ...item,
                subtask: subtasks,
                state: !item.state,
              };
            }
            return item;
          })
        );
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          rel="noopener noreferrer"
          onClick={() => {
            navigate(ROUTES.TASK.subtask + "?create=true", {
              state: {
                task: selectedData,
              },
            });
          }}
        >
          Add Subtask
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          rel="noopener noreferrer"
          onClick={() => {
            setFormData({
              ...formData,
              ...selectedData,
            });
            setEdit(true);
            handleOpenModal();
          }}
        >
          Edit Task
        </a>
      ),
    },
  ];

  const [access, setAccess] = useState<any>(localStorage.getItem("access_id"));

  const [searchParams] = useSearchParams();

  const [selectedData, setSelectedData] = useState<any>({});
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (searchParams.get("create")) {
      handleOpenModal();
    }
  }, [searchParams]);

  return (
    <div className="flex flex-col">
      <ToastContainer />
      <Modal
        width={"80%"}
        className="bg-neutral-1"
        isOpen={isOpen}
        handleSubmit={edit ? updateTask : addTask}
        handleCancel={handleCloseModal}
      >
        <p className="text-lg font-semibold ">{edit ? "Edit" : "Add"} Task</p>
        <div className="grid grid-cols-2 gap-3 mt-[14px]">
          <div className="col-span-2">
            <Input
              label="Task Title"
              placeholder="Enter Task Title"
              value={formData.task_title}
              handleChange={(e: any) => {
                setFormData({ ...formData, task_title: e.target.value });
              }}
            />
          </div>
          <div className="col-span-2">
            <label
              className={"text-[#333333] opacity-70 font-medium text-[16px] "}
            >
              Description
            </label>
            <textarea
              placeholder="Enter Description"
              className="p-[8px] border-[1px] mt-[5px] border-gray-200 h-[100px] w-[100%] rounded-[8px]"
              value={formData.task_desc}
              onChange={(e) => {
                setFormData({ ...formData, task_desc: e.target.value });
              }}
            />
          </div>

          <div className="col-span-1 flex flex-col gap-[5px]">
            <label
              className={"text-[#333333] opacity-70 font-medium text-[16px] "}
            >
              Date Range
            </label>
            <RangePicker
              className="h-[40px]"
              value={
                formData.start_date
                  ? [dayjs(formData.start_date), dayjs(formData.end_date)]
                  : null
              }
              onChange={(e, k) => {
                setFormData({
                  ...formData,
                  start_date: k[0],
                  end_date: k[1],
                });
              }}
            />
          </div>

          <Select
            className="w-full"
            label="Assign Employee"
            options={[...employees]}
            placeholder="Select Employee"
            value={formData.assigned_id || null}
            handleChange={(e: any) => {
              setFormData({ ...formData, assigned_id: e });
            }}
          />
          <Select
            className="w-full"
            label="Project"
            options={[...projects]}
            placeholder="Select Project"
            value={formData.project_id || null}
            handleChange={(e: any) => {
              setFormData({ ...formData, project_id: e });
            }}
          />

          {edit && (
            <Select
              className="w-full"
              label="Status"
              options={[
                { label: "Pending", value: "pending" },
                { label: "In Progress", value: "progress" },
                { label: "Completed", value: "completed" },
                { label: "Update", value: "update" },
              ]}
              placeholder="Select Status"
              value={formData.status || null}
              handleChange={(e: any) => {
                setFormData({ ...formData, status: e });
              }}
            />
          )}
        </div>
      </Modal>

      <div className="flex justify-between items-center">
        <p className="text-lg front-semibold ">Tasks</p>

        <div className="flex gap-[10px] flex-row items-center">
          <input
            type="text"
            placeholder="Search"
            className="border-[1px] border-[#E9E9E9] rounded-[4px] py-[5px] h-[100%] px-[10px] h-[40px]"
          />
          {access < 3 && (
            <button
              className="bg-black py-[8px] px-[20px] rounded-[4px] text-white  "
              onClick={handleOpenModal}
            >
              {" "}
              + Task
            </button>
          )}
        </div>
      </div>

      <div className="grid grid-cols-3 mt-[30px]">
        <Select
          className="w-full"
          label="Select Project"
          options={[...projects]}
          placeholder="Select Project"
          value={filters.project || null}
          handleChange={(e: any) => {
            setFilters({ ...filters, project: e });
          }}
        />
      </div>

      <div className="mt-[30px]">
        <div className="grid p-[10px] bg-gray-100 grid-cols-7">
          <div className="border-r-[1px] border-red">
            <p className="text-left">
              <b>Task Name</b>
            </p>
          </div>
          {/* <div>
            <p className="text-left">
              <b>Task Description</b>
            </p>
          </div> */}

          <div>
            <p className="text-left">
              <b>Task Status</b>
            </p>
          </div>

          <div>
            <p className="text-left">
              <b>Assigned Employee</b>
            </p>
          </div>

          <div>
            <p className="text-left">
              <b>Date</b>
            </p>
          </div>

          <div>
            <p className="text-center">
              <b>Subtask</b>
            </p>
          </div>

          <div>
            <p className="text-right">
              <b>Action</b>
            </p>
          </div>
        </div>

        {data?.map((item: any) => (
          <div className="grid p-[10px] bg-white border-b-[1px] grid-cols-7">
            <div>
              <p
                className="text-left cursor-pointer hover:underline"
                onClick={() => {
                  navigate(ROUTES.TASK.subtask, {
                    state: {
                      task: item,
                    },
                  });
                }}
              >
                {item.task_title}
              </p>
            </div>

            {/* <div>
              <p className="text-left">{item.task_desc}</p>
            </div> */}

            <div>
              <p className="text-left">{item.status}</p>
            </div>

            <div>
              <p className="text-left">
                {
                  employees?.find(
                    (item2: any) => item2.value == item?.assigned_id
                  )?.label
                }
              </p>
            </div>

            <div>
              <p className="text-left">
                {moment(item.start_date).format("DD MMM")} -{" "}
                {moment(item.end_date).format("DD MMM YYYY")}
              </p>
            </div>

            <div>
              <p
                className="text-center cursor-pointer hover:text-underline"
                onClick={() => {
                  navigate(ROUTES.TASK.subtask, {
                    state: {
                      task: item,
                    },
                  });
                }}
              >
                {item.subtask_id?.length}
              </p>
            </div>

            <div className="flex justify-end items-center gap-[20px]">
              {!item?.state ? (
                <FaAngleDown
                  cursor={"pointer"}
                  onClick={(e) => {
                    getSubtaskData(item?.task_id);
                  }}
                />
              ) : (
                <FaAngleUp
                  cursor={"pointer"}
                  onClick={(e) => {
                    setData(
                      data?.map((item2: any) => {
                        if (item2.task_id === item.task_id) {
                          return {
                            ...item2,
                            state: !item2.state,
                          };
                        }
                        return item2;
                      })
                    );
                  }}
                />
              )}

              <Dropdown className="cursor-pointer" menu={{ items }}>
                <BsThreeDotsVertical
                  color="black"
                  onMouseEnter={(e: any) => {
                    setSelectedData(item);
                  }}
                />
              </Dropdown>
            </div>

            {item?.subtask?.length > 0 && item?.state ? (
              <div className=" col-span-7 p-[10px] mt-[20px] bg-gray-100 border-[1px] border-gray-200  ">
                {item?.subtask?.map((item2: any) => (
                  <div className="grid p-[10px] grid-cols-7 border-b-[1px]">
                    <div>
                      <p className="text-left">{item2.task_title}</p>
                    </div>

                    {/* <div>
                      <p className="text-left">{item2.task_desc}</p>
                    </div> */}

                    <div>
                      <p className="text-left">{item2.status}</p>
                    </div>

                    <div>
                      <p className="text-left">
                        {
                          employees?.find(
                            (item3: any) => item3.value == item2?.assigned_id
                          )?.label
                        }
                      </p>
                    </div>

                    <div>
                      <p className="text-left">
                        {moment(item2.start_date).format("DD MMM")} -{" "}
                        {moment(item2.end_date).format("DD MMM YYYY")}
                      </p>
                    </div>

                    <div>
                      <p className="text-left"></p>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Task;
