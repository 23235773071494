import React, { useState, useEffect } from "react";
import { FiFilter } from "react-icons/fi";
import { AiOutlinePlus, AiOutlinePaperClip } from "react-icons/ai";
import { BsPencilFill } from "react-icons/bs";
import Modal from "react-modal";
import { FaBell } from "react-icons/fa";
import { AiFillMessage } from "react-icons/ai";
import { IoIosArrowDropdown } from "react-icons/io";
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import "./Modal.css";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend } from "recharts";
import { DashboardAPI } from "../apis/dashboardapi";
import moment from "moment";
import { ROUTES } from "../routes/RouterConfig";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";

import Select from "../Components/Select/Select";
import { ToastContainer, toast } from "react-toastify";
import Input from "../Components/Input";

import { RiDeleteBack2Line } from "react-icons/ri";
import { WiMoonFull, WiMoonWaningCrescent1 } from "react-icons/wi";

import { IoCloseCircleOutline } from "react-icons/io5";

// import { Tooltip } from "@";

interface Task {
  id: number;
  name: string;
  completed: boolean;
}

interface TodoItem {
  id: number;
  name: string;
  completed: boolean;
  reminder?: Date;
}
interface Meeting {
  name: string;
  time: string;
  date: string;
  link: string;
}
const data = [
  {
    name: "Pritam",
    Hours: 8,
  },
  {
    name: "Ali",
    Hours: 6,
  },
  {
    name: "XYZ",
    Hours: 4,
  },
  {
    name: "ABC",
    Hours: 2,
  },
];

const sortedData = data.sort((a, b) => a.Hours - b.Hours);

const Modal_ = ({
  show,
  onClose,
  onSave,
  handleInputChange,
  projectName,
  date,
  workInfo,
  hours,
  status,
}: any) => {
  const modalClasses = show
    ? "fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
    : "hidden";

  return (
    <div className={modalClasses}>
      <div className="bg-white p-6 rounded-md ">
        <select
          name="projectName"
          value={projectName}
          onChange={handleInputChange}
          className="mb-4 w-full p-2 border rounded text-gray-400"
        >
          <option value="">Select Project</option>
          <option value="project1">Project 1</option>
          <option value="project2">Project 2</option>
          <option value="project3">Project 3</option>
        </select>
        <input
          type="text"
          name="person"
          onChange={handleInputChange}
          placeholder="Assigned to"
          className="mb-4 w-full p-2 border rounded"
        />
        <input
          type="text"
          name="date"
          value={date}
          onChange={handleInputChange}
          placeholder="Due Date"
          className="mb-4 w-full p-2 border rounded"
        />
        <input
          type="text"
          name="workInfo"
          value={workInfo}
          onChange={handleInputChange}
          placeholder="Describe your work"
          className="mb-4 w-full p-2 border rounded"
        />
        <input
          type="number"
          name="hours"
          value={hours}
          onChange={handleInputChange}
          placeholder="No. of Hours Required"
          className="mb-4 w-full p-2 border rounded"
        />
        <select
          name="status"
          onChange={handleInputChange}
          className="mb-4 w-full p-2 border rounded text-gray-400"
          value={status}
        >
          <option value="">Select Status</option>
          <option value="Assigned">Assigned</option>
          <option value="Finished">Finished</option>
          <option value="Pending">Pending</option>
          <option value="On Hold">On Hold</option>
          <option value="Urgent">High Priority</option>
        </select>
        <div className="flex justify-end">
          <button onClick={onClose} className="mr-2 px-4 py-2 border rounded">
            Cancel
          </button>
          <button
            onClick={onSave}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [showModal, setShowModal] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [date, setDate] = useState("");
  const [workInfo, setWorkInfo] = useState("");
  const [status, setStatus] = useState("");
  const [hours, setHours] = useState("");
  const [tasks, setTasks] = useState<any>([]);

  const [boldRows, setBoldRows] = useState<number[]>([]);

  const handleAddAsPriority = (index: number) => {
    const updatedTasks = [...tasks];
    const taskToMove = updatedTasks.splice(index, 1)[0];
    updatedTasks.unshift(taskToMove);
    setTasks(updatedTasks);

    const updatedBoldRows = [0, ...boldRows.map((row) => row + 1)];
    setBoldRows(updatedBoldRows);
  };

  const handleInputChange = (e: any, index: number) => {
    const { name, value } = e.target;

    if (name === "projectName") {
      setProjectName(value);
    } else if (name === "date") {
      setDate(value);
    } else if (name === "workInfo") {
      setWorkInfo(value);
    } else if (name === "status") {
      setStatus(value);
    }
  };

  const handleAddTask = () => {
    console.log("Project Name:", projectName);
    console.log("Date:", date);
    console.log("Work Info:", workInfo);
    console.log("Status:", status);
    const newTask = {
      projectName: projectName,
      date: date,
      workInfo: workInfo,
      status: status,
    };

    setTasks([...tasks, newTask]);
    setProjectName("");
    setDate("");
    setWorkInfo("");
    setStatus("");
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const [taskList, setTaskList] = useState<Task[]>([]);
  const [newTaskName, setNewTaskName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const addTask = () => {
    if (newTaskName.trim() === "") {
      return;
    }

    const newTask: Task = {
      id: Date.now(),
      name: newTaskName,
      completed: false,
    };

    setTaskList((prevTaskList) => [...prevTaskList, newTask]);
    setNewTaskName("");
    setIsModalOpen(false);
  };

  const handleTaskChange = (taskId: number) => {
    setTaskList((prevTaskList) => {
      const updatedTaskList = prevTaskList.map((task) =>
        task.id === taskId ? { ...task, completed: !task.completed } : task
      );

      const completedTaskIndex = updatedTaskList.findIndex(
        (task) => task.id === taskId && task.completed
      );
      if (completedTaskIndex !== -1) {
        const completedTask = updatedTaskList.splice(completedTaskIndex, 1)[0];
        updatedTaskList.push(completedTask);
      } else {
        const uncheckedTaskIndex = updatedTaskList.findIndex(
          (task) => task.id === taskId && !task.completed
        );
        if (uncheckedTaskIndex !== -1) {
          const uncheckedTask = updatedTaskList.splice(
            uncheckedTaskIndex,
            1
          )[0];
          updatedTaskList.unshift(uncheckedTask);
        }
      }

      return updatedTaskList;
    });
  };

  const [todoList, setTodoList] = useState<TodoItem[]>([]);
  const [newTodoName, setNewTodoName] = useState("");
  const [isTodoModalOpen, setIsTodoModalOpen] = useState(false);
  const [currentTodoId, setCurrentTodoId] = useState<number | null>(null);
  const [newTodoReminder, setNewTodoReminder] = useState("");
  const handleTask_Change = (taskId: number) => {
    setTodoList((prevTodoList) => {
      const updatedTodoList = prevTodoList.map((todo) =>
        todo.id === taskId ? { ...todo, completed: !todo.completed } : todo
      );

      const completedTodoIndex = updatedTodoList.findIndex(
        (todo) => todo.id === taskId && todo.completed
      );
      if (completedTodoIndex !== -1) {
        const completedTodo = updatedTodoList.splice(completedTodoIndex, 1)[0];
        updatedTodoList.push(completedTodo);
      }

      return updatedTodoList;
    });
  };

  const handleAddTodo = () => {
    const newTodo = {
      id: todoList.length + 1,
      name: newTodoName,
      completed: false,
      reminder: new Date(newTodoReminder),
    };
    setTodoList([...todoList, newTodo]);
    setNewTodoName("");
    setNewTodoReminder("");
    setIsTodoModalOpen(false);
  };

  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  useEffect(() => {
    const checkReminder = () => {
      const currentTime = new Date().getTime();
      todoList.forEach((todo) => {
        if (todo.reminder && todo.reminder.getTime() <= currentTime) {
          // Display the notification
          setShowNotification(true);
          setNotificationMessage(`Reminder for task '${todo.name}'`);

          // Update the todo item to remove the reminder
          setTodoList((prevTodoList) =>
            prevTodoList.map((t) =>
              t.id === todo.id ? { ...t, reminder: undefined } : t
            )
          );
        }
      });
    };

    const intervalId = setInterval(checkReminder, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [todoList]);

  const [isMeetingModalOpen, setIsMeetingModalOpen] = useState(false);
  const [newMeetingName, setNewMeetingName] = useState("");
  const [newMeetingTime, setNewMeetingTime] = useState("");
  const [newMeetingDate, setNewMeetingDate] = useState("");
  const [newMeetingLink, setNewMeetingLink] = useState("");
  const [scheduledMeeting, setScheduledMeeting] = useState<Meeting | null>(
    null
  );

  const openMeetingModal = () => {
    setIsMeetingModalOpen(true);
  };

  const closeMeetingModal = () => {
    setIsMeetingModalOpen(false);
  };

  const scheduleMeeting = () => {
    setNewMeetingName("");
    setNewMeetingTime("");
    setNewMeetingDate("");
    setNewMeetingLink("");
    setIsMeetingModalOpen(false);
  };

  const handleMeetButtonClick = () => {
    if (newMeetingName && newMeetingTime && newMeetingDate && newMeetingLink) {
      setScheduledMeeting({
        name: newMeetingName,
        time: newMeetingTime,
        date: newMeetingDate,
        link: newMeetingLink,
      });
    }
    setIsMeetingModalOpen(false);
  };

  const [isButtonBold, setIsButtonBold] = useState(false);

  const handleButtonClick = () => {
    setIsButtonBold(true);
  };

  const [editIndex, setEditIndex] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const items: MenuProps["items"] = [
    {
      label: <p>Edit Task</p>,
      key: "0",
    },
    {
      label: <p>Change Status</p>,
      key: "1",
    },
    {
      label: <p>Add for Verification</p>,
      key: "2",
    },
  ];
  const [showSubtasks, setShowSubtasks] = useState<number[]>([]);
  const handleToggleSubtasks = (index: number) => {
    const updatedShowSubtasks = [...showSubtasks];
    if (updatedShowSubtasks.includes(index)) {
      updatedShowSubtasks.splice(updatedShowSubtasks.indexOf(index), 1);
    } else {
      updatedShowSubtasks.push(index);
    }
    setShowSubtasks(updatedShowSubtasks);
  };

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [dashboardData, setDashboardData] = useState<any>({});
  const [employee, setEmployee] = useState<any>([]);

  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);

  const getDashboardData = async (id?: any) => {
    try {
      setLoading(true);

      const obj: any = {};

      if (id) {
        obj["emp_id_each"] = id;
      } else {
        obj["emp_id"] = localStorage.getItem("emp_id");
      }

      const res = await DashboardAPI.getEmployeeDetails(obj);
      if (res.success) {
        setDashboardData(res.data.values.employee_details);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    try {
      const [res1, res2] = await Promise.all([
        DashboardAPI.getEmployeeList(),
        DashboardAPI.getProject(),
      ]);

      if (res1.success) {
        const empList = res1.data.values;
        const empTypes = Object.keys(empList);

        const empArr: any = [];

        empArr.push({
          label: "All",
          value: 1111,
          type: "",
        });

        empTypes.forEach((type) => {
          empList[type].forEach((emp: any) => {
            if (emp?.emp_id == localStorage.getItem("emp_id")) {
              empArr.push({
                label: "Myself",
                type: "",
                value: emp?.emp_id,
              });
            }

            empArr.push({
              label: emp?.employee_name,
              type: type,
              value: emp?.emp_id,
            });
          });
        });

        if (
          !empArr?.find(
            (item: any) => item?.value == localStorage.getItem("emp_id")
          )
        ) {
          empArr.push({
            label: "Myself",
            type: "",
            value: localStorage.getItem("emp_id"),
          });
        }

        setEmployee(empArr);
      }

      if (res2.success) {
        setProjectDetails(
          res2.data.values?.map((item: any) => ({
            label: item?.project_name,
            value: item?.project_id,
            client: item?.client_name,
            description: item?.description,
          }))
        );
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const [projectDetails, setProjectDetails] = useState<any>([]);

  useEffect(() => {
    getData();
    getDashboardData();
  }, []);

  useEffect(() => {
    if (selectedEmployee) {
      if (selectedEmployee == 1111) {
        getDashboardData();
      } else {
        getDashboardData(selectedEmployee);
      }
    }
  }, [selectedEmployee]);

  const [todo, setTodo] = useState<any>("");

  const addTodo = async () => {
    if (!todo) {
      toast.error("Please enter task name");
      return;
    }
    try {
      const obj = {
        emp_id: localStorage.getItem("emp_id"),
        todo_task: todo,
        status: "unseen",
        time: new Date().toISOString(),
      };

      const res = await DashboardAPI.postTodo(obj);
      if (res.success) {
        setTodo("");
        // toast.success(res.message);
        getDashboardData();
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    }
  };

  const updateTodo = async (id: number, status: string) => {
    try {
      const obj = {
        emp_id: localStorage.getItem("emp_id"),
        status: status,
      };

      const res = await DashboardAPI.putTodo(id, obj);
      if (res.success) {
        // toast.success(res.message);
        getDashboardData();
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    }
  };

  const updateNotfications = async (id: number, status: string) => {
    try {
      const obj = {
        emp_id: localStorage.getItem("emp_id"),
        notification_status: status,
      };

      const res = await DashboardAPI.putNotification(id, obj);
      if (res.success) {
        // toast.success(res.message);
        getDashboardData();
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    }
  };

  const [access, setAccess] = useState<any>(
    localStorage.getItem("access_id") || null
  );

  return (
    <div>
      {loading && <Loader />}
      <ToastContainer />
      <div className="grid grid-cols-5 mb-[15px]">
        <div className="col-span-4"></div>
        {access < 4 && (
          <Select
            className="w-full"
            label="Select Employee"
            options={[
              ...employee?.map((item: any) => {
                return {
                  label:
                    item?.label +
                    (item?.type ? " (" + item?.type?.slice(0, -1) + ")" : ""),
                  value: item?.value,
                };
              }),
            ]}
            placeholder="Select Employee"
            value={Number(selectedEmployee) || null}
            handleChange={(e: any) => {
              setSelectedEmployee(e);
              // setFormData({ ...formData, project_id: e });
            }}
          />
        )}
      </div>
      <div className="grid grid-cols-4 gap-7 h-[800px]">
        <div className="bg-neutral-100 rounded-lg min-h-[50px] col-span-2 row-span-3 p-4 overflow-auto">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold">TASKS</h2>
            {/* <button className="ml-[5px]">
              <FiFilter />
            </button> */}
            <div className="flex items-center space-x-2 ml-auto">
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded-lg"
                onClick={() => navigate(ROUTES.TASK.root + "?create=true")}
              >
                <AiOutlinePlus />
              </button>
            </div>
          </div>
          <table className="mt-4 w-full">
            <tbody>
              <tr className="border-gray-400;">
                <td className="font-bold">Task </td>
                <td className="font-bold">Project</td>
                <td className="font-bold">Client</td>
                <td className="font-bold text-center">Due Date</td>
                <td className="font-bold text-center">Status</td>
                <td></td>
              </tr>

              {dashboardData?.assigned?.length > 0 &&
                dashboardData?.assigned?.map((task: any, index: any) => (
                  <React.Fragment key={index}>
                    <tr
                      key={index}
                      className={`border-gray-400 ${boldRows.includes(index)}`}
                    >
                      <td className="py-2">
                        <p
                          className="hover:underline cursor-pointer"
                          onClick={() =>
                            navigate(ROUTES.TASK.root, {
                              state: {
                                project_id: task?.project_id,
                              },
                            })
                          }
                          title={task.task_desc}
                        >
                          {task.task_title}
                        </p>
                      </td>
                      <td className="py-2">
                        <p
                          className="hover:underline cursor-pointer"
                          onClick={() => navigate(ROUTES.PROJECT.root)}
                          title={
                            projectDetails?.find(
                              (item: any) => item.value == task.project_id
                            )?.description
                          }
                        >
                          {
                            projectDetails?.find(
                              (item: any) => item.value == task.project_id
                            )?.label
                          }
                        </p>
                      </td>
                      <td className="py-2">
                        {
                          projectDetails?.find(
                            (item: any) => item.value == task.project_id
                          )?.client
                        }
                      </td>
                      <td className="py-2 text-center">
                        {moment(task.end_date).format("DD MMM YYYY")}
                      </td>
                      <td
                        className={`py-2 text-center uppercase ${
                          task.status === "Urgent"
                            ? "font-bold bg-red-500 rounded-lg text-center"
                            : ""
                        }`}
                        style={{
                          color:
                            task.status === "Assigned"
                              ? "black"
                              : task.status === "Urgent"
                              ? "white"
                              : task.status === "On Hold"
                              ? "#8B8000"
                              : task.status === "Pending"
                              ? "red"
                              : "green",
                        }}
                      >
                        {task.status ? task?.status : "-"}
                      </td>
                      {/* <td className="py-2">
                      <Dropdown menu={{ items }} trigger={["click"]}>
                        <button
                          onClick={() => handleEditTask(index)} 
                          className="ml-[50px]"
                        >
                          <BsPencilFill />
                        </button>
                      </Dropdown>
                      <button
                        className="ml-[20px]"
                      >
                        <AiFillMessage />
                      </button>
                      <button
                        onClick={() => handleToggleSubtasks(index)}
                        className="ml-[20px]"
                      >
                        <IoIosArrowDropdown />
                      </button>
                    </td> */}
                    </tr>
                    {/* {showSubtasks.includes(index) && task.subtasks && (
                      <tr>
                        <td></td>
                        <td
                          className="py-2"
                          colSpan={6}
                          style={{ wordWrap: "break-word", maxWidth: "400px" }}
                        >
                          <ul>
                            {task.subtasks.map((subtask) => (
                              <li key={subtask.id}>
                                <strong> {subtask.title}:</strong>{" "}
                                {subtask.description}
                              </li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    )} */}
                  </React.Fragment>
                ))}
              {dashboardData?.task_details?.map((task: any, index: any) => (
                <React.Fragment key={index}>
                  <tr
                    key={index}
                    className={`border-gray-400 ${boldRows.includes(index)}`}
                  >
                    <td className="py-2">
                      <p
                        className="hover:underline cursor-pointer"
                        onClick={() =>
                          navigate(ROUTES.TASK.root, {
                            state: {
                              project_id: task?.project_id,
                            },
                          })
                        }
                        title={task.task_desc}
                      >
                        {task.task_title}
                      </p>
                    </td>
                    <td className="py-2">
                      <p
                        className="hover:underline cursor-pointer"
                        onClick={() => navigate(ROUTES.PROJECT.root)}
                        title={
                          projectDetails?.find(
                            (item: any) => item.value == task.project_id
                          )?.description
                        }
                      >
                        {
                          projectDetails?.find(
                            (item: any) => item.value == task.project_id
                          )?.label
                        }
                      </p>
                    </td>
                    <td className="py-2">
                      {
                        projectDetails?.find(
                          (item: any) => item.value == task.project_id
                        )?.client
                      }
                    </td>
                    <td className="py-2 text-center">
                      {moment(task.end_date).format("DD MMM YYYY")}
                    </td>
                    <td
                      className={`py-2 text-center uppercase ${
                        task.status === "Urgent"
                          ? "font-bold bg-red-500 rounded-lg text-center"
                          : ""
                      }`}
                      style={{
                        color:
                          task.status === "Assigned"
                            ? "black"
                            : task.status === "Urgent"
                            ? "white"
                            : task.status === "On Hold"
                            ? "#8B8000"
                            : task.status === "Pending"
                            ? "red"
                            : "green",
                      }}
                    >
                      {task.status ? task?.status : "-"}
                    </td>
                    {/* <td className="py-2">
                      <Dropdown menu={{ items }} trigger={["click"]}>
                        <button
                          onClick={() => handleEditTask(index)} 
                          className="ml-[50px]"
                        >
                          <BsPencilFill />
                        </button>
                      </Dropdown>
                      <button
                        className="ml-[20px]"
                      >
                        <AiFillMessage />
                      </button>
                      <button
                        onClick={() => handleToggleSubtasks(index)}
                        className="ml-[20px]"
                      >
                        <IoIosArrowDropdown />
                      </button>
                    </td> */}
                  </tr>
                  {/* {showSubtasks.includes(index) && task.subtasks && (
                      <tr>
                        <td></td>
                        <td
                          className="py-2"
                          colSpan={6}
                          style={{ wordWrap: "break-word", maxWidth: "400px" }}
                        >
                          <ul>
                            {task.subtasks.map((subtask) => (
                              <li key={subtask.id}>
                                <strong> {subtask.title}:</strong>{" "}
                                {subtask.description}
                              </li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    )} */}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

        <div
          className={
            "row-span-3 gap-[20px]  h-[100%] grid grid-rows-3 " +
            (dashboardData?.notification_details
              ? " col-span-1"
              : " col-span-2")
          }
        >
          <div className="bg-neutral-100 rounded-lg row-span-2  p-4 overflow-auto">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-bold">MEETING</h2>
              <button className="ml-[5px]">
                <FiFilter />
              </button>
              <div className="flex items-center space-x-2 ml-auto">
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded-lg"
                  onClick={() => navigate(ROUTES.MEETING.root)}
                >
                  <AiOutlinePlus />
                </button>
              </div>
            </div>

            {/* <div className="date">June 27, 2023</div> */}

            {dashboardData?.meeting_invited?.map((meeting: any, index: any) => (
              <div className="mt-4 flex items-center">
                <div className="ml-4">
                  <div className="flex items-center">
                    <span className="task-name text-md font-bold text-blue-500">
                      {meeting?.meeting_title}
                    </span>
                  </div>
                  <div className="flex items-center space-x-10 mt-2">
                    <span className="project-name text-sm">
                      {moment(meeting?.start_time)?.format(
                        "DD MMM YYYY | HH:mm a"
                      )}
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-2 ml-auto">
                  <button
                    onClick={() => window.open(meeting?.meet_link, "_blank")}
                    className=" hover:bg-blue-600 text-black py-1 px-2 rounded-lg"
                  >
                    <AiOutlinePaperClip />
                  </button>
                </div>
              </div>
            ))}

            {dashboardData?.meeting_details?.map((meeting: any, index: any) => (
              <div className="mt-4 flex items-center">
                <div className="ml-4">
                  <div className="flex items-center">
                    <span className="task-name text-md font-bold">
                      {meeting?.meeting_title}
                    </span>
                  </div>
                  <div className="flex items-center space-x-10 mt-2">
                    <span className="project-name text-sm">
                      {moment(meeting?.start_time)?.format(
                        "DD MM YYYY HH:mm a"
                      )}
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-2 ml-auto">
                  <button
                    onClick={() => window.open(meeting?.meet_link, "_blank")}
                    className=" hover:bg-blue-600 text-black py-1 px-2 rounded-lg"
                  >
                    <AiOutlinePaperClip />
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="bg-neutral-100 row-span-1 rounded-lg p-4 ">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold">LEAVE</h2>
              <div className="space-x-2"></div>
              <div className="flex items-center space-x-2 ml-auto">
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded-lg"
                  onClick={() => navigate(ROUTES.LEAVE.root)}
                >
                  <AiOutlinePlus />
                </button>
              </div>
            </div>
            <div className="date">
              {moment(new Date()).format("MMM DD, YYYY")}
            </div>
            <table className="mt-3 w-full">
              <tbody>
                {dashboardData?.onleave?.map((leave: any, index: any) => (
                  <tr className="border-gray-400">
                    <td className="py-2 capitalize">
                      {localStorage.getItem("emp_id") == leave?.emp
                        ? "me"
                        : employee?.find(
                            (item: any) => item.value === leave.emp
                          )?.label}
                    </td>

                    <td className="py-2 px-2 capitalize">
                      {leave.leave_type === "full-day" ? (
                        <p title={"full day"}>
                          <WiMoonFull />
                        </p>
                      ) : (
                        <p title={"full day"}>
                          <WiMoonWaningCrescent1 />
                        </p>
                      )}
                    </td>
                    <td className="py-2">
                      {moment(leave?.start_date)?.format("DD MMM YY")} -{" "}
                      {moment(leave?.end_date)?.format("DD MMM YY")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row-span-3 flex gap-[20px] flex-col h-[100%] grid gird-rows-3">
          {dashboardData?.notification_details && (
            <div className="row-span-1 bg-neutral-100 rounded-lg p-4 overflow-auto">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-bold">NOTIFICATIONS</h2>
              </div>

              <div className="flex flex-col gap-[10px] mt-[20px]">
                {dashboardData?.notification_details?.map(
                  (notification: any, index: any) => (
                    <div
                      className={
                        " border-[1px] p-[5px] rounded-[10px] cursor-pointer " +
                        (notification.notification_status == "imp"
                          ? " "
                          : "bg-blue-100")
                      }
                      onClick={() =>
                        notification.notification_status == "imp"
                          ? updateNotfications(
                              notification.notification_id,
                              "unseen"
                            )
                          : updateNotfications(
                              notification.notification_id,
                              "imp"
                            )
                      }
                    >
                      <IoCloseCircleOutline
                        onClick={() =>
                          updateNotfications(
                            notification.notification_id,
                            "seen"
                          )
                        }
                        className="ml-auto text-[20px] text-red-500"
                      />
                      <h2 className="text-md font-[500]">
                        {notification?.notification_title}
                      </h2>
                      <p className="text-xs">
                        {notification?.notification_desc}
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          )}

          {dashboardData?.todo_details && (
            <div
              className="bg-neutral-100 rounded-lg min-h-[50px] flex flex-col row-span-3 p-4 overflow-auto overflow-x-hidden"
              style={{ wordWrap: "break-word" }}
            >
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-bold">TO DO LIST</h2>
                {/* <div className="flex items-center space-x-2 ml-auto">
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded-lg"
                    onClick={() => navigate(ROUTES.TODO.root)}
                  >
                    <AiOutlinePlus />
                  </button>
                </div> */}
              </div>
              {dashboardData?.todo_details?.map((task: any) => (
                <div className="mt-[10px]">
                  <div className="flex justify-between">
                    <div className="flex flex-row gap-[10px] w-[100%]">
                      <input
                        type="checkbox"
                        className="task-checkbox"
                        id={`task-${task.id}`}
                        checked={task.status === "imp" ? true : false}
                        onChange={() =>
                          task.status == "imp"
                            ? updateTodo(task.todo_id, "unseen")
                            : updateTodo(task.todo_id, "imp")
                        }
                      />

                      <p
                        className={`task-checkbox-label ${
                          task.status === "imp"
                            ? "text-gray-500  line-through"
                            : ""
                        }`}
                      >
                        <span className="checkbox-icon"></span>
                      </p>

                      <span
                        className={`task-name text-md ml-1 ${
                          task.status === "imp"
                            ? "text-gray-500  line-through"
                            : ""
                        }`}
                      >
                        {task.todo_task}
                      </span>
                    </div>

                    <IoCloseCircleOutline
                      onClick={() => updateTodo(task.todo_id, "seen")}
                      className="ml-[10px] text-[20px] text-red-500"
                    />
                  </div>
                </div>
              ))}

              <div className="flex justify-between mt-[auto]">
                <Input
                  value={todo}
                  handleChange={(e: any) => setTodo(e.target.value)}
                  placeholder="Add Todo"
                  className={`w-[100%]`}
                />
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white h-[40px] px-2 my-auto rounded-lg"
                  onClick={() => addTodo()}
                >
                  <AiOutlinePlus />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
