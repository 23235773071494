import * as yup from 'yup';

export const register = yup.object().shape({
    email: yup.string().email('Invalid email format').required('Email is required'),
    password: yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .required('Password is required'),
    // phone: yup.string().optional().matches(/^\d+$/, 'Invalid phone number'), // Optional phone with digits only validation
    user_name: yup.string().required('Username is required'),
    confirm_password: yup.string()
      .oneOf([yup.ref('password'), undefined], 'Passwords must match')
      .required('Confirm password is required'),
    company: yup.string().optional(), // Optional company field
  });
