import React, { useEffect } from "react";
import Input from "../../Components/Input";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../Components/Loader";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_HOME, LOGIN } from "../../Components/navigation/Constant";
import OtpInput from "../../Components/OtpInput";
import { AuthAPI } from "../../apis/authAPI";

const VerifyOtp = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [employee, setEmployee] = React.useState<any>(
    localStorage.getItem("regsiteredUser")
      ? JSON.parse(localStorage.getItem("regsiteredUser") || "")
      : null
  );

  const [otp, setOtp] = React.useState("");
  const [otpEmail, setOtpEmail] = React.useState("");

  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!otpEmail) {
      return toast.error("Please fill all the details");
    }

    try {
      setLoading(true);
      console.log(employee);
      const data = await AuthAPI.postVerifyOtp({
        emp_shadow_id: employee?.emp_shadow_id,
        // "mobile_otp": Number(otp),
        email_otp: Number(otpEmail),
      });

      if (data.success) {
        toast.success("user registered successfully, login to continue");
        localStorage.setItem("user", JSON.stringify(employee));
        localStorage.setItem("token", data.data.token.access);
        localStorage.setItem("refreshToken", data.data.token.access);
        localStorage.setItem("user", JSON.stringify(employee));
        localStorage.setItem("emp_id", employee?.emp_id);

        setTimeout(() => {
          navigate(LOGIN);
        }, 2000);
      }
    } catch (error: any) {
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (otpEmail.length === 4) {
      handleLogin();
    }
  }, [otpEmail]);

  return (
    <div className="bg-[#F4F4F496]">
      <div className="min-h-[100vh] mx-auto flex flex-col items-center justify-center ">
        <ToastContainer />
        {loading && <Loader />}

        <div className="flex w-[100%] items-start ">
          {/* <img src={logo} alt="" className='w-[300px]' /> */}
        </div>
        <div className="grid grid-cols-6 w-full min-h-[100vh]">
          <div className="col-span-2 max-md:hidden flex flex-col justify-center items-center bg-[#D9D9D9] text-center space-y-4">
            <h1 className="text-4xl font-bold">Hello</h1>
            <p className="font-light">Add your details  &
              Manage your work easily</p>
              <a href="/register" className="pink-btn">CREATE ACCOUNT</a>
          </div>
          <div className="col-span-6 flex flex-col justify-center md:col-span-4 gap-[10px] card w-[80%] rounded-[8px] mx-auto">
            <h1 className="text-center text-[rgb(51,51,51)] text-3xl font-bold ">
              VERIFY OTP
            </h1>
            <h3 className="text-center text-xl font-semibold mt-[30px]">
              Enter Your OTP
            </h3>
            <div className="flex flex-col gap-[10px] w-[70%] mt-[30px] mx-auto">
              <p className="text-semibold text-center text-[#000]/[0.5]">
                OTP has been sent to your email id{" "}
              </p>
              <div
                className={"mt-[15px] flex items-center justify-center flex-col"}
              >
                <label className="text-[#333333] opacity-70 font-semibold text-[16px]">
                  Enter Email OTP
                </label>
                <OtpInput
                  value={otpEmail}
                  valueLength={4}
                  onChange={(value) => setOtpEmail(value)}
                />
              </div>
            </div>

            <button
              className="bg-blue-500 text-[#fff] text-[14px] px-[45px] mx-auto font-semibold rounded-[12px] py-[8px] mt-[15px]"
              onClick={() => {
                handleLogin();
              }}
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
