import React from "react";
import { Select } from "antd";

const CustomSelect = ({
  label,
  placeholder,
  options,
  value,
  handleChange,
  name,
  multiple = false,
  required,
  labelStyle,
  selectStyle,
  disabled,
}: any) => {
  return (
    <div className="flex flex-col">
      <label
        className={
          "text-[#333333] opacity-70 text-[16px] font-medium " + labelStyle ||
          ""
        }
      >
        {label} {required ? <span className="text-[#FF0000]">*</span> : null}
      </label>
      {multiple ? (
        <Select
          showSearch
          mode={"multiple"}
          value={value}
          disabled={disabled}
          className="h-[40px] border-[1px] border-gray-200 "
          style={{
            width: "100%",
            height: "40px",
            marginTop: "5px",
            borderRadius: "4px",
          }}
          onChange={(e) => {
            handleChange(e);
          }}
          placeholder={placeholder || "Search to Select"}
          options={options}
        />
      ) : (
        <Select
          showSearch
          // name={String(name)}
          value={value}
          disabled={disabled}
          className="h-[40px] border-[1px] border-gray-200 "
          style={{
            width: "100%",
            height: "40px",
            marginTop: "5px",
            borderRadius: "4px",
          }}
          onChange={(e) => {
            handleChange(e);
          }}
          placeholder={placeholder || "Search to Select"}
          options={options}
        />
      )}
    </div>
  );
};

export default CustomSelect;
