import React, { useEffect } from 'react'
import Input from '../../Components/Input'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../Components/Loader'
import { useNavigate } from 'react-router-dom'
import { FORGOT, REGISTER,LOGIN } from '../../Components/navigation/Constant'
import { EMAIL_REGEX } from '../../constants/regex'
import axios from 'axios';
import { AuthAPI } from '../../apis/authAPI'


const initialErrorState = {
    error: false,
    msg: ''
}

const ResetPassword = () => {
    const [newpassword, setNewPassword] = React.useState('')
    const [confirmpassword, setConfirmPassword] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [formErr, setFormErr] = React.useState({
        password: initialErrorState
    })

    const [loading, setLoading] = React.useState(false)

    const navigate = useNavigate()

    const handleSubmit = async (e: any) => {

        if (!newpassword || !confirmpassword) {
            return toast.error('Please fill all the details', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            });
        }

        if (newpassword !== confirmpassword) {
            return toast.error('Password does not match')
        }

        try {
            setLoading(true)
            const data = await AuthAPI.postResetPassword({ new_password: newpassword, email: email })
            if (data.success) {
                toast.success(data.message);
                sessionStorage.removeItem('passwordReset')
                navigate(LOGIN)
            }

        } catch (error: any) {
            console.log('here')
            const errMsg = error?.response?.data?.message || error?.message || "something went wrong"
            console.log(errMsg)
            return toast.error(errMsg, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            });
        } finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        const email = sessionStorage.getItem('passwordReset')
        if (!email) {
            navigate(FORGOT)
            toast.error("something went wrong")
            return
        }

        setEmail(String(email))
    }, [])
    

    return (
        <div className='bg-[#F4F4F496]'>
            <ToastContainer />
            {
                loading && <Loader />
            }
            <div className='min-h-[100vh] container py-[30px]  mx-auto flex flex-col items-center justify-center'>


                <div className='flex w-[100%] items-start'>
                    {/* <img src={logo} alt="" className='w-[300px]' /> */}
                </div>
                <div className='flex flex-col gap-[10px] card w-[40%] shadow-[0px_0px_8px_rgba(0,0,0,0.1)] bg-[#ffffff] py-[30px] px-[20px] rounded-[8px]  mx-auto'>
                    <h3 className='text-center text-blue-500 text-3xl font-bold'>
                        Forgot Password?
                    </h3>
                    <div className='flex flex-col gap-[10px] w-[70%] mt-[30px] mx-auto'>
                        <Input label="New Password" name="newpassword" type="password" placeholder="New password" className="bg-[#F4F4F4] rounded-[12px]" handleChange={(e: any) => setNewPassword(e.target.value)} value={newpassword} errTxt={formErr?.password?.msg} />
                        <Input label="Confirm Password" name="confirmpassword" type="password" placeholder="Confirm password" className="bg-[#F4F4F4] rounded-[12px]" handleChange={(e: any) => setConfirmPassword(e.target.value)} value={confirmpassword} errTxt={formErr?.password?.msg} />


                    </div>
                    <p className='text-center hover:underline cursor-pointer' onClick={() => { navigate(LOGIN) }} >Back to Login</p>
                    <button className="bg-blue-500 text-[#fff] text-[14px] px-[45px] mx-auto font-semibold rounded-[12px] py-[8px] mt-[15px]"
                        onClick={(e: any) => handleSubmit(e)}
                    >Submit</button>


                </div>

            </div>
        </div>
    )
}

export default ResetPassword 