import { useCallback, useEffect, useMemo, useState } from "react";

import Modal from "../../../Components/Modal";
// import CustomPagination from "../../../components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";

import {
  Calendar,
  Views,
  momentLocalizer,
  DateLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { DatePicker, Space } from "antd";
import { DashboardAPI } from "../../../apis/dashboardapi";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

const { RangePicker } = DatePicker;

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const CalendarPage = () => {
  const [myEvents, setEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [view, setView] = useState<any>(Views.WEEK);

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(),
    }),
    []
  );

  const [date, setDate] = useState(new Date());

  const getCalendarData = async () => {
    const startDate = moment(date).subtract(1, "days");

    const endDate = moment(date).add(view === "day" ? 1 : 6, "days");

    try {
      setLoading(true);

      let res = await DashboardAPI.getCalendar({
        start: moment(startDate).format("YYYY-MM-DD"),
      });

      let eventArr: any[] = [];
      // const res = await getEvaluatorCalendar(`?from_date=${dateTimeFormatForApi(startDate)}&to_date=${dateTimeFormatForApi(endDate)}`);
      if (res.success) {
        // console.log(res.data);

        Object.keys(res.data.values[0])?.forEach(
          (item: any) => (eventArr = [...eventArr, ...res.data.values[0][item]])
        );

        // Object.keys(res.data.values[0])?.map(
        //   (item: any) => (eventArr = [...eventArr, ...res.data.values[0][item]])
        // );

        setEvents([
          ...eventArr?.map((item: any) => ({
            id: item.id,
            title: item?.title,
            type: item?.type,
            start: new Date(item?.start),
            end: new Date(item?.end),
            resourceId: [2, 3],
          })),
        ]);
        // setEvents(
        //   res.data.calendar?.map((item: any) => ({
        //     id: item.id,
        //     title: item?.meeting_title,
        //     start: new Date(item?.meeting_from_time),
        //     end: new Date(item?.meeting_to_time),
        //     resourceId: [2, 3],
        //   }))
        // );
      }
    } catch (err: any) {
      console.log(err);
      const errMsg = err?.response?.data?.message || err?.message || err;
      toast.warn(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCalendarData();
  }, [date]);

  return (
    <div className="flex flex-col">
      <ToastContainer />

      <div className="flex justify-between items-center">
        <p className="text-lg front-semibold">Calendar</p>
      </div>

      <div className="mt-[60px] min-h-[500px]">
        <Calendar
          // step={60}
          // // timeslots={1}
          // defaultDate={defaultDate}
          // defaultView={Views.MONTH}
          // events={[...myEvents]}
          // localizer={localizer}
          // views={{
          //   day: true,
          //   week: true,
          //   month: true,
          //   agenda: false,
          //   // year: Year
          // }}
          // formats={{
          //   dayHeaderFormat: (date, culture, localizer) =>
          //     localizer ? localizer.format(date, "ddd, DD MMM", culture) : "",
          //   dayFormat: (date, culture, localizer) =>
          //     localizer ? localizer.format(date, "ddd, DD MMM", culture) : "",
          // }}
          // getDrilldownView={(
          //   targetDate,
          //   currentViewName,
          //   configuredViewNames
          // ) => {
          //   setView(currentViewName);
          // }}
          // // onSelectEvent={handleSelectEvent}
          // // onSelectSlot={handleSelectSlot}
          // // selectable
          // onNavigate={(date) => {
          //   setDate(date);
          // }}
          // scrollToTime={scrollToTime}
          components={{
            event: EventComponent,
          }}
          events={[...myEvents]}
          localizer={localizer}
          step={60}
          views={["month", "week", "day"]}
          defaultDate={defaultDate}
          popup={false}
          // onShowMore={(events, date) => this.setState({ showModal: true, events })}
        />
      </div>
    </div>
  );
};

export default CalendarPage;

const EventComponent = ({ event }: any) => {
  const [isAvailable, setIsAvailable] = useState(
    event?.title === "Available Time"
  );

  const colors = ["#20C9AC", "#00A5FF", "#FFC82C", "#FF6B00", "#FF0000"];
  const selectedColor = colors[Math.floor(Math.random() * colors?.length)];

  console.log(isAvailable);
  return (
    <div className={"p-[5px] rounded-[8px] bg-[var(--primary)]"}>
      <p className="text-white capitalize">
        {event?.title} <span className="text-italic">( {event?.type} )</span>
      </p>
    </div>
  );
};
