import React from "react";

import { Route, Routes } from "react-router-dom";
import Register from "../../Pages/Auth/Register";
import Forgot from "../../Pages/Auth/Forgot";
import Layout from "../Layout";
import Dashboard from "../../Pages/Dashboard";
import Admin from "../../Pages/Admin";
import Login from "../../Pages/Auth/Login";

import Employee from "../../Pages/Dashboard/Employee";
import {
  LOGIN,
  REGISTER,
  FORGOT,
  DASHBOARD,
  DASHBOARD_HOME,
  ROOT,
  VERIFY_OTP,
  RESET,
  CHANGE_PASSWORD,
  ADMIN,
  EMPLOYEE,
} from "./Constant";
import VerifyOtp from "../../Pages/Auth/VerifyOtp";
import ChangePassword from "../../Pages/Auth/ChangePassword";
import Reset from "../../Pages/Auth/Reset";
import NotFound from "../../Pages/NotFound";
import { ROUTES } from "../../routes/RouterConfig";
import Project from "../../Pages/Dashboard/Project";
import Task from "../../Pages/Dashboard/Task";
import SubTask from "../../Pages/Dashboard/Task/Subtask";
import Meeting from "../../Pages/Dashboard/Meeting";
import TODO from "../../Pages/Dashboard/Todo";
import Calendar from "../../Pages/Dashboard/Calendar";
import Leave from "../../Pages/Dashboard/Leave";
import Messages from "../../Pages/Dashboard/Messages";
export const RouterConfig = () => {
  return (
    <Routes>
      <Route path={ROOT} element={<Login />} />
      <Route path={LOGIN} element={<Login />} />
      <Route path={REGISTER} element={<Register />} />
      <Route path={FORGOT} element={<Forgot />} />
      <Route path={VERIFY_OTP} element={<VerifyOtp />} />
      <Route path={RESET} element={<Reset />} />
      <Route path={CHANGE_PASSWORD} element={<ChangePassword />} />

      <Route path={DASHBOARD} element={<Layout />}>
        <Route path={DASHBOARD_HOME} element={<Dashboard />}></Route>
        <Route path={ADMIN} element={<Admin />}></Route>
        <Route path={ROUTES.EMPLOYEE.root} element={<Employee />}></Route>
        <Route path={ROUTES.PROJECT.root} element={<Project />}></Route>
        <Route path={ROUTES.TASK.root} element={<Task />}></Route>
        <Route path={ROUTES.TASK.subtask} element={<SubTask />}></Route>
        <Route path={ROUTES.MEETING.root} element={<Meeting />}></Route>
        <Route path={ROUTES.TODO.root} element={<TODO />}></Route>
        <Route path={ROUTES.LEAVE.root} element={<Leave />}></Route>
        <Route path={ROUTES.CALENDAR.root} element={<Calendar />}></Route>
        <Route path={ROUTES.MESSAGES.root} element={<Messages />}></Route>
        <Route path={"*"} element={<NotFound />}></Route>
      </Route>

      <Route path={"*"} element={<NotFound />}></Route>
    </Routes>
  );
};
