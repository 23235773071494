export const ROOT = "/";

export const LOGIN = "/login";
export const REGISTER = "/register";
export const VERIFY_OTP = "/verify-otp";
export const CHANGE_PASSWORD = "/change-password";

export const DASHBOARD = "/dashboard";

//DASHBOARD
export const DASHBOARD_HOME = "/dashboard/home";
export const EMPLOYEE = "/dashboard/employee";
export const PROJECT = "/dashboard/project";

export const ADMIN="/dashboard/admin"

export const FORGOT = "/forgot";
export const RESET = "/reset";
