import { useEffect, useState } from "react";

import { Table, Radio } from "antd";
import Modal from "../../../Components/Modal";
// import CustomPagination from "../../../components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes/RouterConfig";
import Input from "../../../Components/Input/Input";
import Select from "../../../Components/Select/Select";
import { ToastContainer, toast } from "react-toastify";
import { DashboardAPI } from "../../../apis/dashboardapi";
import Loader from "../../../Components/Loader";
import { BiSolidEditAlt } from "react-icons/bi";

const Employee = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [viewState, setViewState] = useState("employee");

  const handleCloseModal = () => {
    setIsOpen(false);
    setFormData({
      name: "",
      email: "",
      access_id: "",
    });
    setEdit(false);
  };
  const handleOpenModal = () => setIsOpen(true);
  const [edit, setEdit] = useState(false);

  const [selectedEmployee, setSelectedEmployee] = useState<any>({});

  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState({});
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await DashboardAPI.getEmployeeList();
      if (res.success) {
        const empList = res.data.values;
        const empTypes = Object.keys(empList);

        const empArr: any = [];

        empTypes.forEach((type) => {
          empList[type].forEach((emp: any) => {
            empArr.push({
              employee_name: emp?.employee_name,
              emp_id: emp?.emp_id,
              email: emp?.email,
              type: type,
              access_id: emp?.access_id,
            });
          });
        });

        console.log(empArr);

        setData(empArr);
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const columnsEmployee = [
    {
      title: "Name",
      dataIndex: "employee_name",
      key: "employee_name",
      render: (index: any, val: any) => {
        return (
          <p
            className="cursor-pointer"
            onClick={(e) => {
              console.log(index, val);
              //   navigate(ROUTES.Doctor.AppointmentHistory + "/" + val.id);
            }}
          >
            {index}
          </p>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "type",
      key: "type",
      render: (index: any, val: any) => {
        return (
          <p
            className="cursor-pointer"
            onClick={(e) => {
              console.log(index, val);
              //   navigate(ROUTES.Doctor.AppointmentHistory + "/" + val.id);
            }}
          >
            {index}
          </p>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => {
        console.log(record);
        return access < "3" ? (
          <>
            {" "}
            <div className="flex gap-[10px] flex-row items-center">
              <button
                className="text-white py-[5px] h-[100%] px-[10px]"
                title="option"
                onClick={(e) => {
                  e.preventDefault();
                  setFormData({
                    name: record.employee_name,
                    email: record.email,
                    access_id: record.access_id,
                  });
                  setSelectedEmployee(record);
                  handleOpenModal();
                  setEdit(true);
                }}
              >
                <BiSolidEditAlt color="black" size={20} />
              </button>
            </div>
          </>
        ) : (
          "-"
        );
      },
    },
  ];

  const columnsClient = [
    {
      title: "Client Name",
      dataIndex: "employee_name",
      key: "employee_name",
      render: (index: any, val: any) => {
        return (
          <p
            className="cursor-pointer"
            onClick={(e) => {
              console.log(index, val);
              //   navigate(ROUTES.Doctor.AppointmentHistory + "/" + val.id);
            }}
          >
            {index}
          </p>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Members",
      dataIndex: "members",
      key: "members",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => {
        return access < 3 ? (
          <>
            {" "}
            <div className="flex gap-[10px] flex-row items-center">
              <button
                className="text-white py-[5px] h-[100%] px-[10px]"
                title="option"
                onClick={(e) => {
                  e.preventDefault();
                  setFormData({
                    name: record.employee_name,
                    email: record.email,
                    access_id: record.access_id,
                  });
                  handleOpenModal();
                  setEdit(true);
                }}
              >
                <BiSolidEditAlt color="black" size={20} />
              </button>
            </div>
          </>
        ) : (
          "-"
        );
      },
    },
  ];

  const [value, setValue] = useState(1);

  useEffect(() => {
    getData();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    access_id: "",
  });

  const addEmployee = async () => {
    if (!formData.name || !formData.email || !formData.access_id) {
      toast.error("Please fill all the fields");
      return;
    }

    try {
      setLoading(true);
      const res = await DashboardAPI.postEmployee({
        employee_name: formData?.name,
        ...formData,
      });
      if (res.success) {
        toast.success(res.message);
        handleCloseModal();
        getData();
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const updateEmployee = async () => {
    try {
      setLoading(true);
      const res = await DashboardAPI.putEmployee(selectedEmployee?.emp_id, {
        employee_name: formData?.name,
        ...formData,
      });
      if (res.success) {
        toast.success(res.message);
        handleCloseModal();
        getData();
      }
    } catch (err: any) {}
  };

  const [access, setAccess] = useState<any>(
    localStorage.getItem("access_id") || null
  );

  return (
    <div className="flex flex-col">
      <ToastContainer />
      {loading && <Loader />}
      <Modal
        width={"80%"}
        className="bg-neutral-1"
        isOpen={isOpen}
        handleSubmit={() => (edit ? updateEmployee() : addEmployee())}
        handleCancel={handleCloseModal}
      >
        <p className="text-lg font-semibold ">
          Add {viewState === "client" ? "Client" : "Employee"}
        </p>
        <div className="grid grid-cols-2 gap-3 mt-[14px]">
          <Input
            label="Name"
            placeholder="Enter Name"
            value={formData.name}
            handleChange={(e: any) => {
              setFormData({ ...formData, name: e.target.value });
            }}
          />
          <Input
            label="Email"
            placeholder="Enter  Email"
            value={formData.email}
            handleChange={(e: any) => {
              setFormData({ ...formData, email: e.target.value });
            }}
          />
          <Select
            className="w-full"
            label="Role"
            options={[
              { label: "Manager", value: 2 },
              { label: "Leader", value: 3 },
              { label: "Employee", value: 4 },
              { label: "Client", value: 5 },
            ]}
            placeholder="Select Role"
            value={formData.access_id || null}
            handleChange={(e: any) => {
              setFormData({
                ...formData,
                access_id: e,
              });
            }}
          />
        </div>
      </Modal>

      <div className="flex justify-between items-center">
        <p className="text-lg front-semibold ">
          {viewState === "client" ? "Clients" : "Employees"}
        </p>

        <div className="flex gap-[10px] flex-row items-center">
          <input
            type="text"
            placeholder="Search"
            className="border-[1px] border-[#E9E9E9] rounded-[4px] py-[5px] h-[100%] px-[10px] h-[40px]"
          />
          {access == "1" && (
            <button
              className="bg-black py-[8px] px-[20px] rounded-[4px] text-white  "
              onClick={handleOpenModal}
            >
              {" "}
              + {viewState === "client" ? "Client" : "Employee"}
            </button>
          )}
        </div>
      </div>

      {/* <div className="mt-[30px] flex justify-between items-center">
        <h6 className="text-Medium+/Label/Medium ">{""}</h6>
        
      </div> */}

      <div className="flex mt-[45px] gap-[20px]">
        <button
          onClick={() => setViewState("employee")}
          className={
            viewState === "employee"
              ? "bg-black py-[8px] px-[20px] rounded-[4px] text-white"
              : "bg-[#E9E9E9] py-[8px] px-[20px] rounded-[4px] text-black"
          }
        >
          View Employee
        </button>

        {access < 3 && (
          <button
            onClick={() => setViewState("client")}
            className={
              viewState === "client"
                ? "bg-black py-[8px] px-[20px] rounded-[4px] text-white"
                : "bg-[#E9E9E9] py-[8px] px-[20px] rounded-[4px] text-black"
            }
          >
            View Clients
          </button>
        )}
      </div>

      <div className="mt-[30px]">
        <Table
          columns={viewState == "client" ? columnsClient : columnsEmployee}
          dataSource={
            viewState === "client"
              ? data?.filter((item: any) => item.type == "clients")
              : data?.filter((item: any) => item.type !== "clients")
          }
        />
        {/* <CustomPagination currentPage={1} /> */}
      </div>
    </div>
  );
};

export default Employee;
