import { useEffect, useState } from "react";

import { Table, Radio } from "antd";
import Modal from "../../../Components/Modal";
// import CustomPagination from "../../../components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes/RouterConfig";
import Input from "../../../Components/Input/Input";
import Select from "../../../Components/Select/Select";

import { DatePicker, Space } from "antd";
import { DashboardAPI } from "../../../apis/dashboardapi";
import { ToastContainer, toast } from "react-toastify";
import { BiEdit } from "react-icons/bi";

import { TimePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const Meeting = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => setIsOpen(false);
  const handleOpenModal = () => setIsOpen(true);

  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState({});
  const navigate = useNavigate();

  const [data, setData] = useState<any>([]);
  const [employees, setEmployees] = useState<any>([]);
  const [projects, setProjects] = useState<any>([]);

  const [access, setAccess] = useState<any>(
    localStorage.getItem("access_id") || null
  );

  const getData = async () => {
    try {
      const [res1, res2, res3] = await Promise.all([
        DashboardAPI.getProject(),
        DashboardAPI.getEmployeeList(),
        DashboardAPI.getMeeting(),
      ]);

      if (res1.success) {
        setProjects(
          res1.data.values?.map((item: any) => ({
            label: item?.project_name,
            value: item?.project_name,
          }))
        );
      }

      if (res2.success) {
        const empList = res2.data.values;
        const empTypes = Object.keys(empList);

        const empArr: any = [];

        empTypes.forEach((type) => {
          empList[type].forEach((emp: any) => {
            if (emp?.emp_id === localStorage.getItem("emp_id")) return;
            if (type === "clients" && access > 2) return;
            empArr.push({
              label: emp?.employee_name,
              value: emp?.emp_id,
              type: type,
            });
          });
        });

        setEmployees(empArr);
      }

      if (res3.success) {
        setData(res3.data.values);
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Project Name",
      dataIndex: "project_title",
      key: "project_title",
      render: (index: any, val: any) => {
        return (
          <p
            className="cursor-pointer"
            onClick={(e) => {
              console.log(index, val);
              //   navigate(ROUTES.Doctor.AppointmentHistory + "/" + val.id);
            }}
          >
            {index}
          </p>
        );
      },
    },
    {
      title: "Meeting Title",
      dataIndex: "meeting_title",
      key: "meeting_title",
    },
    {
      title: "Meeting Link",
      dataIndex: "meet_link",
      render: (index: any, val: any) => {
        return <a className="cursor-pointer">{index}</a>;
      },
    },
    {
      title: "Members",
      dataIndex: "members",
      key: "members",
      render: (index: any, val: any) => {
        return (
          <p className="cursor-pointer">
            {employees?.find((item: any) => item.value === index[0])?.label}
          </p>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "start_time",
      render: (_: any, record: any) => {
        return (
          <p>
            {record?.start_time?.split("T")[0]} -{" "}
            {record?.end_time?.split("T")[0]}
          </p>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => {
        return (
          <>
            <BiEdit size={20} className="cursor-pointer" />
          </>
        );
      },
    },
  ];

  const addMeeting = async () => {
    if (!formData.meeting_title) {
      toast.error("Please enter project name");
      return;
    } else if (!formData.project_title) {
      toast.error("Please enter start date");
      return;
    } else if (!formData.start_date) {
      toast.error("Please enter end date");
      return;
    } else if (!formData.start_time) {
      toast.error("Please enter start time");
      return;
    } else if (!formData.end_time) {
      toast.error("Please select end time");
      return;
    }

    try {
      const res = await DashboardAPI.postMeeting({
        ...formData,
        members: formData.members,
        start_time: moment(
          formData?.start_date + " " + formData.start_time,
          "YYYY-MM-DD HH:mm a"
        ).toISOString(),
        end_time: moment(
          formData?.start_date + " " + formData.end_time,
          "YYYY-MM-DD HH:mm a"
        ).toISOString(),
      });
      if (res.success) {
        toast.success("Meeting Added Successfully");
        handleCloseModal();
        getData();
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [formData, setFormData] = useState({
    project_title: "",
    meeting_title: "",
    start_date: "",
    start_time: "",
    end_time: "",
    meeting_status: "",
    meet_link: "",
    meeting_desc: "",
    members: [],
  });

  return (
    <div className="flex flex-col">
      <ToastContainer />
      <Modal
        width={"80%"}
        className="bg-neutral-1"
        isOpen={isOpen}
        handleSubmit={addMeeting}
        handleCancel={handleCloseModal}
      >
        <p className="text-lg font-semibold ">Add Meeting</p>
        <div className="grid grid-cols-2 gap-3 mt-[14px]">
          <div className="col-span-2">
            <Select
              className="w-full"
              label="Project"
              options={[...projects]}
              placeholder="Select Project"
              value={formData.project_title || null}
              handleChange={(e: any) => {
                setFormData({ ...formData, project_title: e });
              }}
            />
          </div>
          <div className="col-span-2">
            <Input
              label="Meeting Title"
              placeholder="Enter Meeting Title"
              value={formData.meeting_title || ""}
              handleChange={(e: any) => {
                setFormData({ ...formData, meeting_title: e.target.value });
              }}
            />
          </div>
          <div className="col-span-2">
            <label
              className={"text-[#333333] opacity-70 font-medium text-[16px] "}
            >
              Description
            </label>
            <textarea
              onChange={(e) => {
                setFormData({ ...formData, meeting_desc: e.target.value });
              }}
              placeholder="Enter Description"
              className="p-[8px] border-[1px] mt-[5px] border-gray-200 h-[100px] w-[100%] rounded-[8px]"
            />
          </div>

          <div className="col-span-1 flex flex-col gap-[5px]">
            <label
              className={"text-[#333333] opacity-70 font-medium text-[16px] "}
            >
              Date
            </label>
            <DatePicker
              className="w-full"
              onChange={(date:any,dateString:string|string[]) => {
                const isArray = Array.isArray(dateString)
                setFormData({ ...formData, start_date:isArray?(dateString[0]||""):dateString});
              }}
            />
          </div>

          <div className="col-1 flex flex-col gap-[5px]">
            <label
              className={"text-[#333333] opacity-70 font-medium text-[16px] "}
            >
              Time
            </label>
            <TimePicker.RangePicker
              format={"HH:mm a"}
              onChange={(e: any, time) => {
                setFormData({
                  ...formData,
                  start_time: time[0],
                  end_time: time[1],
                });
              }}
            />
          </div>

          <div className="col-span-2">
            <Input
              label="Meeting Link"
              placeholder="Enter Meeting Link"
              value={formData.meet_link || ""}
              handleChange={(e: any) => {
                setFormData({ ...formData, meet_link: e.target.value });
              }}
            />
          </div>

          <Select
            className="w-full"
            label="Employee"
            multiple
            options={[...employees]}
            placeholder="Select Employee"
            value={formData.members || null}
            handleChange={(e: any) => {
              setFormData({ ...formData, members: e });
            }}
          />

          <Select
            className="w-full"
            label="Status"
            options={[
              { label: "Pending", value: "pending" },
              { label: "In Progress", value: "in_progress" },
              { label: "Completed", value: "completed" },
              { label: "Update", value: "update" },
            ]}
            placeholder="Select Status"
            value={formData.meeting_status || null}
            handleChange={(e: any) => {
              setFormData({ ...formData, meeting_status: e });
            }}
          />
        </div>
      </Modal>

      <div className="flex justify-between items-center">
        <p className="text-lg front-semibold">Meetings</p>

        <div className="flex gap-[10px] flex-row items-center">
          <input
            type="text"
            placeholder="Search"
            className="border-[1px] border-[#E9E9E9] rounded-[4px] py-[5px] h-[100%] px-[10px] h-[40px]"
          />
          <button
            className="bg-black py-[8px] px-[20px] rounded-[4px] text-white  "
            onClick={handleOpenModal}
          >
            {" "}
            + Meeting
          </button>
        </div>
      </div>

      {/* <div className="mt-[30px] flex justify-between items-center">
        <h6 className="text-Medium+/Label/Medium ">{""}</h6>
        
      </div> */}

      <div className="mt-[30px]">
        <Table columns={columns} dataSource={data} />
        {/* <CustomPagination currentPage={1} /> */}
      </div>
    </div>
  );
};

export default Meeting;
