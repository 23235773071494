import React from "react";

const Input = ({
  label,
  placeholder,
  value,
  handleChange,
  name,
  required,
  labelStyle,
  inputStyle,
  type = "text",
  disabled,
  style,
  inputId,
  readOnly,
}: any) => {
  return (
    <div className="flex flex-col" style={style}>
      <label
        className={
          "text-[#333333] opacity-70 font-medium text-[16px] " + labelStyle ||
          ""
        }
      >
        {label} {required ? <span className="text-[#FF0000]">*</span> : null}
      </label>
      <input
        readOnly={readOnly}
        disabled={disabled}
        name={name}
        type={type}
        value={value}
        onChange={handleChange}
        id={inputId}
        className={
          inputStyle
            ? inputStyle
            : " rounded-[4px] px-[10px] py-[5px] h-[40px] mt-[5px] flex items-center border-[1px]"
        }
        placeholder={placeholder}
      />
    </div>
  );
};

export default Input;
