export const ROUTES = {
  Home: "/dashboard/home",
  EMPLOYEE: {
    root: "/dashboard/employee",
  },
  PROJECT: {
    root: "/dashboard/project",
  },
  TASK: {
    root: "/dashboard/task",
    subtask: "/dashboard/task/subtask",
  },
  MEETING: {
    root: "/dashboard/meeting",
  },
  TODO: {
    root: "/dashboard/todo",
  },
  LEAVE: {
    root: "/dashboard/leave",
  },
  CALENDAR: {
    root: "/dashboard/calendar",
  },
  MESSAGES: {
    root: "/dashboard/messages",
  },
};
