import { useEffect, useState } from "react";

import { Table, Radio } from "antd";
import Modal from "../../../Components/Modal";
// import CustomPagination from "../../../components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes/RouterConfig";
import Input from "../../../Components/Input/Input";
import Select from "../../../Components/Select/Select";

import { DatePicker, Space } from "antd";
import { DashboardAPI } from "../../../apis/dashboardapi";
import { ToastContainer, toast } from "react-toastify";
import { BiEdit } from "react-icons/bi";

import { TimePicker } from "antd";

import dayjs from "dayjs";
import moment from "moment";

const { RangePicker } = DatePicker;

const Leave = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => setIsOpen(false);
  const handleOpenModal = () => setIsOpen(true);

  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState({});
  const navigate = useNavigate();

  const [data, setData] = useState<any>([]);
  const [employees, setEmployees] = useState<any>([]);

  const [access, setAccess] = useState<any>(
    localStorage.getItem("access_id") || null
  );
  //   const [projects, setProjects] = useState<any>([]);

  const getData = async () => {
    try {
      const [res1, res2] = await Promise.all([
        DashboardAPI.getEmployeeList(),
        DashboardAPI.getLeave(),
      ]);

      if (res1.success) {
        const empList = res1.data.values;
        const empTypes = Object.keys(empList);

        const empArr: any = [];

        empTypes.forEach((type) => {
          empList[type].forEach((emp: any) => {
            // if (emp?.emp_id === localStorage.getItem("emp_id")) return;
            if (type === "clients") return;
            empArr.push({
              label: emp?.employee_name,
              value: emp?.emp_id,
              type: type,
            });
          });
        });

        setEmployees(empArr);
      }

      if (res2.success) {
        setData(res2.data.values);
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Employee",
      dataIndex: "emp",
      key: "emp",
      render: (index: any, val: any) => {
        return (
          <p>{employees?.find((item: any) => val.emp == item?.value)?.label}</p>
        );
      },
    },
    {
      title: "Leve Type",
      dataIndex: "leave_type",
      key: "leave_type",
      render: (index: any, val: any) => {
        return (
          <p
            className="cursor-pointer capitalize"
            onClick={(e) => {
              console.log(index, val);
              //   navigate(ROUTES.Doctor.AppointmentHistory + "/" + val.id);
            }}
          >
            {index?.split("-").join(" ")}
          </p>
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      render: (_: any, record: any) => {
        return <p>{moment(record.start_date)?.format("DD MMM YYYY")}</p>;
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      render: (_: any, record: any) => {
        return <p>{moment(record.end_date)?.format("DD MMM YYYY")}</p>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => {
        return (
          <>
            <BiEdit size={20} className="cursor-pointer" />
          </>
        );
      },
    },
  ];

  const addTask = async () => {
    if (!formData.emp) {
      toast.error("Please select employee");
      return;
    }
    if (!formData.leave_type) {
      toast.error("Please select leave type");
      return;
    }
    if (!formData.start_date) {
      toast.error("Please select start date");
      return;
    }
    if (!formData.end_date) {
      toast.error("Please select end date");
      return;
    }

    try {
      const res = await DashboardAPI.postLeave({
        ...formData,
        start_date: moment(formData.start_date).format("YYYY-MM-DD"),
        end_date: moment(formData.end_date).format("YYYY-MM-DD"),
      });
      if (res.success) {
        toast.success("Leave Added Successfully");
        handleCloseModal();
        getData();
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [formData, setFormData] = useState({
    leave_type: "",
    start_date: "",
    end_date: "",
    emp: localStorage.getItem("emp_id"),
  });

  return (
    <div className="flex flex-col">
      <ToastContainer />
      <Modal
        width={"80%"}
        className="bg-neutral-1"
        isOpen={isOpen}
        handleSubmit={addTask}
        handleCancel={handleCloseModal}
      >
        <p className="text-lg font-semibold ">Add Leave</p>
        <div className="grid grid-cols-2 gap-3 mt-[14px]">
          <Select
            className="w-full"
            label="Employee"
            options={[...employees]}
            placeholder="Select Employee"
            value={formData.emp || null}
            handleChange={(e: any) => {
              if (access < 3) {
                setFormData({ ...formData, emp: e });
              }
            }}
          />
          <Select
            className="w-full"
            label="Leave Type"
            options={[
              { label: "Full Day", value: "full-day" },
              { label: "Half Day", value: "half-day" },
            ]}
            placeholder="Select Leave Type"
            value={formData.leave_type || null}
            handleChange={(e: any) => {
              setFormData({ ...formData, leave_type: e });
            }}
          />

          <div className="col-span-2 flex flex-col gap-[5px]">
            <label
              className={"text-[#333333] opacity-70 font-medium text-[16px] "}
            >
              Date Range
            </label>
            <RangePicker
              className="h-[40px]"
              onChange={(e: any, k: any) => {
                setFormData({
                  ...formData,
                  start_date: k[0],
                  end_date: k[1],
                });
              }}
            />
          </div>
        </div>
      </Modal>

      <div className="flex justify-between items-center">
        <p className="text-lg front-semibold">Leave</p>

        <div className="flex gap-[10px] flex-row items-center">
          <input
            type="text"
            placeholder="Search"
            className="border-[1px] border-[#E9E9E9] rounded-[4px] py-[5px] h-[100%] px-[10px] h-[40px]"
          />
          <button
            className="bg-black py-[8px] px-[20px] rounded-[4px] text-white  "
            onClick={handleOpenModal}
          >
            {" "}
            + Add Leave
          </button>
        </div>
      </div>

      {/* <div className="mt-[30px] flex justify-between items-center">
        <h6 className="text-Medium+/Label/Medium ">{""}</h6>
        
      </div> */}

      <div className="mt-[30px]">
        <Table columns={columns} dataSource={data} />
        {/* <CustomPagination currentPage={1} /> */}
      </div>
    </div>
  );
};

export default Leave;
