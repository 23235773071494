import React, { useState, useEffect } from 'react'
import Input from '../../Components/Input'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../Components/Loader'
import { useNavigate } from 'react-router-dom'
import { LOGIN } from '../../Components/navigation/Constant'
import { EMAIL_REGEX } from '../../constants/regex'
import OtpInput from '../../Components/OtpInput'
import { AuthAPI } from '../../apis/authAPI'
import { RESET } from '../../Components/navigation/Constant'


const initialErrorState = {
    error: false,
    msg: ''
}

const ForgotPassword = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [emailSent, setEmailSent] = useState(false)
    const [otp, setOtp] = useState('')

    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e: any) => {

        if (!email) {
            return toast.error('Please fill all the details', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            });
        }

        try {
            setLoading(true)
            const data = await AuthAPI.postForgotPassword({ email })

            if (data.success) {
                setEmailSent(true);
                toast.success(data.message);
            }

        } catch (error: any) {
            console.log('here')
            const errMsg = error?.response?.data?.message || error?.message || "something went wrong"
            console.log(errMsg)
            return toast.error(errMsg, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            });
        } finally {
            setLoading(false)
        }
    }


    const submitOtp = async () => {

        if (!otp) {
            return toast.error('Please fill all the details', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            });
        }

        try {
            setLoading(true)
            const data = await AuthAPI.postResetPasswordEmailOTP({ email, email_otp: Number(otp) })

            if (data.success) {
                toast.success(data.message);
                sessionStorage.setItem('passwordReset', email)
                navigate(RESET)
            }

        } catch (error: any) {
            console.log('here')
            const errMsg = error?.response?.data?.message || error?.message || "something went wrong"
            console.log(errMsg)
            return toast.error(errMsg, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (otp.length === 4) {
            submitOtp();
        }
    }, [otp]);


    // Forgot
    return (
        <div className='bg-[#F4F4F496]'>
            <ToastContainer />
            {
                loading && <Loader />
            }
            <div className='min-h-[100vh] grid grid-cols-6  mx-auto items-center justify-center'>

                {/* <div className='flex w-[100%] items-start '>
                    {/* <img src={logo} alt="" className='w-[300px]' />
                </div> */}
                <div className="col-span-2 max-md:hidden min-h-[100vh] flex flex-col justify-center items-center bg-[#D9D9D9] text-center space-y-4">
                    <h1 className="text-4xl font-bold">Hello</h1>
                    <p className="font-light">Add your details  &
                        Manage your work easily</p>
                    <a href="/register" className="pink-btn">CREATE ACCOUNT</a>
                </div>

                <div className='col-span-4 flex flex-col gap-[10px] w-[80%] md:w-[60%] shadow-[0px_0px_8px_rgba(0,0,0,0.1)] bg-[#ffffff] py-[30px] px-[20px] rounded-[8px]  mx-auto'>
                    <h1 className='text-center text-blue-500 text-3xl font-bold'></h1>
                    <h3 className='text-center text-2xl font-bold'>
                        Forgot Password?
                    </h3>
                    <div className='flex flex-col gap-[10px] w-[70%] mt-[30px] mx-auto'>
                        <Input
                            disabled={emailSent}
                            label="Email" name="email" type="email" placeholder="Enter your email" handleChange={(e: any) => setEmail(e.target.value)} />
                        {
                            emailSent ? <>
                                < OtpInput value={otp} valueLength={4} onChange={(value) => setOtp(value)} />
                            </> : null
                        }
                        <p className='text-right hover:underline cursor-pointer' onClick={() => { navigate(LOGIN) }} >Back to Login</p>
                    </div>{
                        !emailSent ? <button className="pink-btn w-[60%] mx-auto" onClick={(e: any) => handleSubmit(e)}
                        >Reset Password</button> : <button className="pink-btn w-[60%]" onClick={(e: any) => submitOtp()}
                        >Submit OTP</button>

                    }
                </div>

            </div>
        </div>

    )
}

export default ForgotPassword 