import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  UserOutlined,
  HomeOutlined,
  CalendarOutlined,
  EditOutlined,
  MessageOutlined,
  SettingOutlined,
  AppstoreAddOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";

import { MdMeetingRoom } from "react-icons/md";
import { FcLeave } from "react-icons/fc";

import { Button, Layout, Menu, theme } from "antd";

// import logo from "../../assets/images/logo.png";
import { FaBahtSign } from "react-icons/fa6";

import { useEffect, useState } from "react";

import { Outlet, useNavigate } from "react-router-dom";

import { ROUTES } from "../routes/RouterConfig";
import { AuthAPI } from "../apis/authAPI";
import { LOGIN } from "./navigation/Constant";
import { BsPerson } from "react-icons/bs";
import { DashboardAPI } from "../apis/dashboardapi";
import { SiHomeassistantcommunitystore } from "react-icons/si";

// import companyLogo from "../assets/logo.png";

const styleIcon = {
  fontSize: "18px",
  fontWeight: "bold",
};

const { Header, Sider, Content } = Layout;

const keyRouteMap = {
  "1": ROUTES.Home,
  "2": ROUTES.EMPLOYEE.root,
  "3": ROUTES.PROJECT.root,
  "4": ROUTES.TASK.root,
  "5": ROUTES.MEETING.root,
  "6": ROUTES.TODO.root,
  "7": ROUTES.LEAVE.root,
  "8": ROUTES.CALENDAR.root,
  "9": ROUTES.MESSAGES.root,
};

const DoctorLayout = () => {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [selectedKey, setSelectedKey] = useState("1");

  const onClickMenuItem = (e: any) => {
    setSelectedKey(e.key);
    navigate(keyRouteMap[e.key as keyof typeof keyRouteMap]);
  };

  useEffect(() => {
    const path = window.location.pathname;
    const key: any = Object.keys(keyRouteMap).find(
      (key) => keyRouteMap[key as keyof typeof keyRouteMap] === path
    );
    setSelectedKey(key);
  }, []);

  const [isCardWhite, setIscardWhite] = useState(true);

  const [user, setuser] = useState<any>({});

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (!userData) {
      //   navigate(LOGIN)
      return;
    }
    setuser(JSON.parse(userData || "{}"));
  }, []);

  const logout = async () => {
    try {
      localStorage.removeItem("user");
      navigate(LOGIN);
      const data = await AuthAPI?.postLogout();
    } catch (err: any) {
      console.log(err);
      const errMsg = err?.response?.data?.message || "Something went wrong";
      alert(errMsg);
    }
  };

  const [accessId, setAccessId] = useState<any>(
    localStorage.getItem("access_id")
  );

  const [company, setCompany] = useState<any>({});

  const getCompany = async () => {
    try {
      const res = await DashboardAPI.getCompanyById(user?.company_id);

      if (res.success) {
        setCompany(res.data.values);
      }
    } catch (err: any) {
      console.log("error getting company :: ", err);
    } finally {
    }
  };

  useEffect(() => {
    if (user?.company_id) {
      getCompany();
    }
  }, [user?.company_id]);

  return (
    <Layout className="min-h-[100vh] flex">
      <Sider
        trigger={null}
        theme="light"
        collapsible
        collapsed={collapsed}
        width="220px"
        className="p-[10px] py-[20px] bg-[#fff] flex flex-col h-[100vh] min-h-[100vh]"
      >
        <div className="flex flex-col h-[100%] justify-between">
          <div>
            <img
              src={"/assets/logo.png"}
              className="h-[80px] object-contain mr-auto"
            />
            {/* <FaBahtSign fontSize={30} /> */}
            <Menu
              className="mt-[35px] outline-0 border-r-0 border-white border-[0px] flex flex-col gap-[15px] bg-white"
              mode="inline"
              defaultSelectedKeys={["1"]}
              selectedKeys={[selectedKey]}
              onClick={onClickMenuItem}
              items={[
                {
                  key: "1",
                  icon: <HomeOutlined style={styleIcon} />,
                  label: "Dashboard",
                },
                {
                  key: "2",
                  icon: <UserOutlined style={styleIcon} />,
                  label: "Employee",
                },
                {
                  key: "3",
                  icon: <AppstoreAddOutlined style={styleIcon} />,
                  label: "Projects",
                },
                {
                  key: "4",
                  icon: <EditOutlined style={styleIcon} />,
                  label: "Task",
                },
                {
                  key: "5",
                  icon: <MdMeetingRoom style={styleIcon} />,
                  label: "Meetings",
                },
                // {
                //   key: "6",
                //   icon: <OrderedListOutlined style={styleIcon} />,
                //   label: "TODO",
                // },
                {
                  key: "7",
                  icon: <FcLeave style={styleIcon} />,
                  label: "Leave",
                },

                {
                  key: "8",
                  icon: <CalendarOutlined style={styleIcon} />,
                  label: "Calendar",
                },
                {
                  key: "9",
                  icon: <MessageOutlined style={styleIcon} />,
                  label: "Messages",
                },
                // {
                //   key: "9",
                //   icon: <SettingOutlined style={styleIcon} />,
                //   label: "Setttings",
                // },
              ]}
            />
          </div>

          <div className="flex flex-col gap-[10px] items-center justify-center">
            <div
              className={
                " flex items-center mx-auto p-[5px] flex-col " +
                (!collapsed
                  ? " bg-gray-100 w-[100%] flex items-center rounded-[10px] "
                  : "")
              }
            >
              <p className="text-md font-[500] mb-[10px] ">
                {accessId == "1"
                  ? "Admin"
                  : accessId == "2"
                  ? "Manager"
                  : accessId == "3"
                  ? "Leader"
                  : accessId == "4"
                  ? "Employee"
                  : accessId == "5"
                  ? "Client"
                  : ""}
              </p>
              <div className="border-[1px] border-blue-400 rounded-[50%] p-[10px] bg-gray-100">
                <BsPerson style={{ fontSize: "20px" }} />
              </div>
              {/* {!collapsed ? (
                <span className="ml-2 font-medium text-md capitalize">
                  {user?.employee_name}
                </span>
              ) : null} */}
            </div>
            <button
              className="bg-linear flex gap-[10px] justify-center py-[12px] w-[100%] align text-red-500 text-Medium+/Label/Small-Strong rounded-[8px]"
              onClick={() => {
                logout();
              }}
            >
              {" "}
              <LogoutOutlined style={styleIcon} /> {collapsed ? "" : "Logout"}{" "}
            </button>
          </div>
        </div>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />

          <p className="mr-[50px] font-[500] text-gray-700 text-xl uppercase flex gap-[10px] items-center">
            <SiHomeassistantcommunitystore color="black" />
            {company?.name}
          </p>

          <p className="mr-[50px] font-[500] text-lg">{user?.employee_name}</p>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            maxHeight: "calc(100vh - 120px)",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <Outlet context={{ setIscardWhite }} />
        </Content>
      </Layout>
    </Layout>
  );
};
export default DoctorLayout;
