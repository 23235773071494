import React, { useEffect } from "react";
import Input from "../../Components/Input";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  DASHBOARD_HOME,
  FORGOT,
  CHANGE_PASSWORD,
} from "../../Components/navigation/Constant";
import { REGISTER } from "../../Components/navigation/Constant";
import { AuthAPI } from "../../apis/authAPI";
import { EMAIL_REGEX } from "../../constants/regex";
import Loader from "../../Components/Loader";
const initialErrorState = {
  error: false,
  msg: "",
};

const Trial = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [formErr, setFormErr] = React.useState({
    email: initialErrorState,
    password: initialErrorState,
  });

  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!email || !password) {
      return toast.error("Please fill all the details", {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
      });
    }

    try {
      setLoading(true);
      const data = await AuthAPI.postLogin({ username: email, password });

      if (data.success) {
        if (data.data?.is_emp_created === false) {
          navigate(CHANGE_PASSWORD + "?email=" + email);
          return;
        }

        console.log(data);
        toast.success(data.message || "User logged in successfully");
        localStorage.setItem("token", data.data.token.access);
        localStorage.setItem("refreshToken", data.data.token.access);
        localStorage.setItem("user", JSON.stringify(data.data.emp_details));
        localStorage.setItem("emp_id", data.data.emp_details?.emp_id);
        localStorage.setItem("access_id", data.data.emp_details?.access_id);


        navigate(DASHBOARD_HOME);
      }
    } catch (error: any) {
      console.log("here");
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg, {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const validate = (e: any) => {
    const { name, value } = e.target;

    if (name === "email") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          email: {
            error: true,
            msg: "Please enter email",
          },
        }));
      } else if (!EMAIL_REGEX.test(email)) {
        setFormErr((prev: any) => ({
          ...prev,
          email: {
            error: true,
            msg: "Please enter valid email",
          },
        }));
      } else {
        setFormErr((prev: any) => ({ ...prev, email: initialErrorState }));
      }
    } else if (name === "password") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          password: {
            error: true,
            msg: "Please enter password",
          },
        }));
      } else {
        setFormErr((prev: any) => ({ ...prev, password: initialErrorState }));
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  });

  const handleKeyPress = (event: any) => {
    if (event.keyCode === 13) {
      handleLogin();
    }
  };

  return (
    <div>
      <div className="mx-auto flex flex-col items-center justify-center">
        <ToastContainer />

        {loading && <Loader />}

        <div className="flex w-[100%] items-start">
          <img src={""} alt="" className="w-[300px]" />
        </div>
        <div className="grid grid-cols-6 h-[100] w-full">
          <div className="col-span-2 max-md:hidden flex flex-col justify-center items-center bg-[#D9D9D9] text-center space-y-4">
            <h1 className="text-4xl font-bold">Hello</h1>
            <p className="font-light">Add your details  &
              Manage your work easily</p>
              <a href="/register" className="pink-btn">CREATE ACCOUNT</a>
          </div>
          <div className="col-span-4 max-md:col-span-6 min-h-[100vh] py-[30px]">
            <div className="flex flex-col gap-[10px] card w-[50%] mx-auto">
              <div className="flex w-[100%] justify-center">
                <img src={"/assets/logo.png"} alt="" className="w-[100px]" />
              </div>
              <h1 className="text-center text-black-500 text-3xl text-[#333333]">
                SIGN IN
              </h1>
              <div className="flex flex-col gap-[10px] w-full mt-[30px] mx-auto">
                <label htmlFor="email" className="text-gray-500 text-sm">Email</label>
                <Input
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  className="rounded-[12px] h-[50px]"
                  handleChange={handleChange}
                  onBlur={validate}
                  value={email}
                  errTxt={formErr?.email?.msg}
                />
                <label htmlFor="email" className="text-gray-500 text-sm">Password</label>
                <Input
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  className="text-gray-500 text-sm py-2 h-[50px]"
                  handleChange={handleChange}
                  value={password}
                  onBlur={validate}
                  errTxt={formErr?.password?.msg}
                />
    
                <p
                  className="text-right hover:underline cursor-pointer"
                  onClick={() => {
                    navigate(FORGOT);
                  }}
                >
                  Forgot Password?
                </p>
              </div>
              <button
                className="bg-[#FD3798] text-white py-3 font-bold text-xl px-6 rounded-full w-[200px] mx-auto"
                onClick={() => {
                  handleLogin();
                }}
              >
                Sign In
              </button>
    
              <p className="text-[#000] text-center text-[#000]/[0.7] mt-[15px] font-medium">
                Don't have a DigiTracker account yet?{" "}
                <span
                  className="text-right text-blue-500 hover:underline cursor-pointer"
                  onClick={() => {
                    navigate(REGISTER);
                  }}
                >
                  Sign up
                </span>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trial;
