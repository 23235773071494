import yup from 'yup';

interface YupErrors {
    [key: string]: any; // Index signature to allow string-based indexing
  }
export interface ErrorOne{
    error?:boolean;
    msg?:string;
}

interface Errors{
    [key: string]: ErrorOne;
}

export async function toErrMessage<T>(schema: yup.Schema<any>, values: any): Promise<T> {
    try {
      var validationResult = await schema.validate(values, { abortEarly: false }); // Validate and collect all errors
      return {} as T;
    } catch (error:any) {
      // Handle other types of errors (e.g., non-Yup errors)
      const errors:Errors={};
      for (const fieldError of error?.inner||[]) {
        errors[fieldError.path] = {
          error: true,
          msg: fieldError.message,
        };
      }
  
      return errors as T;
    }
  }