import { useEffect, useState } from "react";

import { Table, Radio } from "antd";
import Modal from "../../../Components/Modal";
// import CustomPagination from "../../../components/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes/RouterConfig";
import Input from "../../../Components/Input/Input";
import Select from "../../../Components/Select/Select";

import { DatePicker, Space } from "antd";
import { DashboardAPI } from "../../../apis/dashboardapi";
import { ToastContainer, toast } from "react-toastify";
import { BiEdit } from "react-icons/bi";

import { TimePicker } from "antd";

import dayjs from "dayjs";
import moment from "moment";

const { RangePicker } = DatePicker;

const TODO = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => setIsOpen(false);
  const handleOpenModal = () => setIsOpen(true);

  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState({});
  const navigate = useNavigate();

  const [data, setData] = useState<any>([]);
  //   const [employees, setEmployees] = useState<any>([]);
  //   const [projects, setProjects] = useState<any>([]);

  const getData = async () => {
    try {
      const [res1] = await Promise.all([
        // DashboardAPI.getProject(),
        // DashboardAPI.getEmployee(),
        DashboardAPI.getTodo(),
      ]);

      if (res1.success) {
        setData(res1.data.values);
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Task Name",
      dataIndex: "todo_task",
      key: "todo_task",
      render: (index: any, val: any) => {
        return (
          <p
            className="cursor-pointer"
            onClick={(e) => {
              console.log(index, val);
              //   navigate(ROUTES.Doctor.AppointmentHistory + "/" + val.id);
            }}
          >
            {index}
          </p>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      render: (_: any, record: any) => {
        return (
          <p>
            {moment(record.time)?.format("DD MMM YYYY HH:mm a")}
          </p>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: any, record: any) => {
        return <p>{record.status}</p>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => {
        return (
          <>
            <BiEdit size={20} className="cursor-pointer" />
          </>
        );
      },
    },
  ];

  const addTask = async () => {
    if (!formData.todo_task) {
      toast.error("Please enter task name");
      return;
    } else if (!formData.time) {
      toast.error("Please enter time");
      return;
    }

    try {
      const res = await DashboardAPI.postTodo({
        ...formData,
        time: new Date(formData.time)?.toISOString(),
      });
      if (res.success) {
        toast.success("Todo Added Successfully");
        handleCloseModal();
        getData();
      }
    } catch (err: any) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [formData, setFormData] = useState({
    todo_task: "",
    time: "",
    status: "",
  });

  return (
    <div className="flex flex-col">
      <ToastContainer />
      <Modal
        width={"80%"}
        className="bg-neutral-1"
        isOpen={isOpen}
        handleSubmit={addTask}
        handleCancel={handleCloseModal}
      >
        <p className="text-lg font-semibold ">Add Todo</p>
        <div className="grid grid-cols-2 gap-3 mt-[14px]">
          <div className="col-span-2">
            <Input
              label="Task Name"
              placeholder="Enter Task Name"
              value={formData.todo_task || ""}
              handleChange={(e: any) => {
                setFormData({ ...formData, todo_task: e.target.value });
              }}
            />
          </div>

          <div className="col-span-1 flex flex-col gap-[5px]">
            <label
              className={"text-[#333333] opacity-70 font-medium text-[16px] "}
            >
              Time
            </label>
            <TimePicker
            format={"HH:mm a"}
              onChange={(e: any, k: any) => {
                setFormData({ ...formData, time: e["$d"] });
              }}
              className="h-[100%]"
              defaultOpenValue={dayjs("00:00:00", "HH:mm:ss")}
            />
          </div>

          <Select
            className="w-full"
            label="Status"
            options={[
              { label: "Pending", value: "pending" },
              { label: "In Progress", value: "in_progress" },
              { label: "Completed", value: "completed" },
              { label: "Update", value: "update" },
            ]}
            placeholder="Select Status"
            value={formData.status || null}
            handleChange={(e: any) => {
              setFormData({ ...formData, status: e });
            }}
          />
        </div>
      </Modal>

      <div className="flex justify-between items-center">
        <p className="text-lg front-semibold">Todo</p>

        <div className="flex gap-[10px] flex-row items-center">
          <input
            type="text"
            placeholder="Search"
            className="border-[1px] border-[#E9E9E9] rounded-[4px] py-[5px] h-[100%] px-[10px] h-[40px]"
          />
          <button
            className="bg-black py-[8px] px-[20px] rounded-[4px] text-white  "
            onClick={handleOpenModal}
          >
            {" "}
            + Add Todo
          </button>
        </div>
      </div>

      {/* <div className="mt-[30px] flex justify-between items-center">
        <h6 className="text-Medium+/Label/Medium ">{""}</h6>
        
      </div> */}

      <div className="mt-[30px]">
        <Table columns={columns} dataSource={data} />
        {/* <CustomPagination currentPage={1} /> */}
      </div>
    </div>
  );
};

export default TODO;
