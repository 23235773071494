import React, { useEffect, useState } from "react";
import { Select } from "antd";
import Input from "../../Components/Input";
import { useNavigate } from "react-router-dom";
import { LOGIN, VERIFY_OTP } from '../../Components/navigation/Constant';
import { toast, ToastContainer } from "react-toastify";
import { AuthAPI } from "../../apis/authAPI";
import Loader from "../../Components/Loader";
import { EMAIL_REGEX } from "../../constants/regex";
import { PHONE_REGEX } from "../../constants/regex";
import { register } from "../../schemas/auth";
import { ErrorOne, toErrMessage } from "../../utils/validator";

const initialErrorState:ErrorOne = {
  error: false,
  msg: "",
};

const initialData = {
  email: "",
  password: "",
  phone: "",
  user_name: "",
  confirm_password: "",
  company: "",
  country:null
};

interface RegisterError{
  common: ErrorOne;
  user_name: ErrorOne;
  email: ErrorOne;
  company: ErrorOne;
  password: ErrorOne;
  confirm_password: ErrorOne;
}

const Register = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = useState(initialData);


  const [extention, setExtention] = useState([]);

  const navigate = useNavigate();

  const handleLogin = async () => {
    validate();
    if (
      !formData.email ||
      !formData.password ||
      !formData.user_name ||
      !formData.confirm_password||
      !formData.company
    ) {
      return toast.error("Please fill all the fields");
    }

    if (validateSubmit()) {
      return toast.error("Please fill all the details correctly");
    }

    try {
      setLoading(true);
      const data = await AuthAPI.postRegister({
        user_name: formData.user_name,
        email: formData.email,
        //      mobile_no: Number(formData.phone),
        comp_name: formData.company,
        password: formData.password,
        confirm_password: formData.confirm_password,
        country:"India"
      });

      if (data.success) {
        navigate(VERIFY_OTP);
        toast.success(data.message);
        localStorage.setItem(
          "regsiteredUser",
          JSON.stringify(data.data.values)
        );
      }
    } catch (error: any) {
      console.log("here");
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg, {
        position: "top-right",
        // autoClose: 5000,
        closeOnClick: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // const getExtention = async () => {
  //   try {
  //     const data = await AuthAPI.getExtentionList()
  //     if (data.success) {
  //       setExtention(data?.data?.extension.map((item: any) => ({ label: item, value: item })))
  //     }
  //   } catch (error: any) {
  //     console.log('here')
  //     const errMsg = error?.response?.data?.message || error?.message || "something went wrong"
  //     console.log(errMsg)
  //     return toast.error(errMsg, {
  //       position: "top-right",
  //       // autoClose: 5000,
  //       closeOnClick: true,
  //     });
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const [formErr, setFormErr] = useState<RegisterError>({
    common:initialErrorState,
    user_name: initialErrorState,
    email: initialErrorState,
    company: initialErrorState,
    password: initialErrorState,
    confirm_password: initialErrorState,
  });

  const validate = async()=>{
    const formErr = await toErrMessage<RegisterError>(register,formData);
    console.log(formErr);
    setFormErr(formErr);
  }

  // const validate = (e: any) => {
  //   const { name, value } = e.target;

  //   if (name === "email") {
  //     if (!value) {
  //       setFormErr((prev: any) => ({
  //         ...prev,
  //         email: {
  //           error: true,
  //           msg: "Please enter email",
  //         },
  //       }));
  //     } else if (!EMAIL_REGEX.test(value)) {
  //       setFormErr((prev: any) => ({
  //         ...prev,
  //         email: {
  //           error: true,
  //           msg: "Please enter valid email",
  //         },
  //       }));
  //     } else {
  //       setFormErr((prev: any) => ({ ...prev, email: initialErrorState }));
  //     }
  //   } else if (name === "password") {
  //     if (!value) {
  //       setFormErr((prev: any) => ({
  //         ...prev,
  //         password: {
  //           error: true,
  //           msg: "Please enter password",
  //         },
  //       }));
  //     } else {
  //       setFormErr((prev: any) => ({ ...prev, password: initialErrorState }));
  //     }
  //   } else if (name === "user_name") {
  //     if (!value) {
  //       setFormErr((prev: any) => ({
  //         ...prev,
  //         user_name: {
  //           error: true,
  //           msg: "Please enter user name",
  //         },
  //       }));
  //     } else {
  //       setFormErr((prev: any) => ({ ...prev, user_name: initialErrorState }));
  //     }
  //   }
  //   //   }  else if (name === 'phone') {
  //   //     if (!value) {
  //   //       setFormErr((prev: any) => ({
  //   //         ...prev, phone: {
  //   //           error: true,
  //   //           msg: 'Please enter phone number'
  //   //         }
  //   //       }))
  //   //     } else if (!PHONE_REGEX.test(value)) {
  //   //       setFormErr((prev: any) => ({
  //   //         ...prev, phone: {
  //   //           error: true,
  //   //           msg: 'Please enter valid phone number'
  //   //         }
  //   //       }))
  //   //     } else {
  //   //       setFormErr((prev: any) => ({ ...prev, phone: initialErrorState }))
  //   //     }
  //   //   }
  //   else if (name == "confirm_password") {
  //     if (!value) {
  //       setFormErr((prev: any) => ({
  //         ...prev,
  //         confirm_password: {
  //           error: true,
  //           msg: "Please enter confirm password",
  //         },
  //       }));
  //     } else if (value != formData.password) {
  //       setFormErr((prev: any) => ({
  //         ...prev,
  //         confirm_password: {
  //           error: true,
  //           msg: "Password and confirm password should be same",
  //         },
  //       }));
  //     } else {
  //       setFormErr((prev: any) => ({
  //         ...prev,
  //         confirm_password: initialErrorState,
  //       }));
  //     }
  //   } else if (name == "password") {
  //     if (!value) {
  //       setFormErr((prev: any) => ({
  //         ...prev,
  //         password: {
  //           error: true,
  //           msg: "Please enter password",
  //         },
  //       }));
  //     } else {
  //       setFormErr((prev: any) => ({ ...prev, password: initialErrorState }));
  //     }
  //   }
  // };

  const validateSubmit = () => {
    if (
      formErr?.user_name ||
      formErr?.email ||
      formErr?.password ||
      formErr?.confirm_password||
      formErr?.company
    ) {
      return true;
    }
  };

  // useEffect(() => {
  //   getExtention()
  // }, [])

  return (
    <div>
      <div className="min-h-[100vh] mx-auto flex flex-col items-center">
        <ToastContainer />
        {loading && <Loader />}

        <div className="grid grid-cols-6 w-full">
          <div className="md:col-span-4 col-span-6 flex flex-col gap-[10px] card w-[80%] shadow-[0px_0px_8px_rgba(0, 0, 0, 0.1)] bg-[#ffffff] py-[30px] px-[20px] rounded-[8px] mx-auto">
            <h1 className="text-center text-4xl font-light text-[#333333]">
              CREATE AN ACCOUNT
            </h1>
  
            <div className="grid grid-cols-2 gap-[15px] w-[70%] mt-[30px] mx-auto">
              <div className="col-span-2">
                <Input
                  label="User Name"
                  name="user_name"
                  type="text"
                  placeholder="Enter User Name"
                  handleChange={handleChange}
                  className="bg-[#F4F4F4] rounded-[12px]"
                  value={formData?.user_name}
                  onBlur={validate}
                />
              </div>
              <div className="col-span-2">
                <Input
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Enter your Email"
                  handleChange={handleChange}
                  className="bg-[#F4F4F4] rounded-[12px]"
                  value={formData?.email}
                  onBlur={validate}
                  errTxt={formErr?.email?.msg}
                />
              </div>
              <div className="col-span-2">
                <Input
                  label="Company"
                  name="company"
                  type="text"
                  placeholder="Enter your Company Name"
                  handleChange={handleChange}
                  className="bg-[#F4F4F4] rounded-[12px]"
                  value={formData?.company}
                  onBlur={validate}
                  errTxt={formErr?.company?.msg}
                />
              </div>
  
              <div className="col-span-2">
                <Input
                  label="Company"
                  name="company"
                  type="text"
                  placeholder="Enter your Company Name"
                  handleChange={handleChange}
                  className="bg-[#F4F4F4] rounded-[12px]"
                  value={formData?.company}
                  onBlur={validate}
                  errTxt={formErr?.company?.msg}
                />
              </div>
  
              {/* <div className="col-span-2">
                      <label className="text-[#333333] opacity-70 font-semibold text-[14px]">Mobile No.</label>
                      <div className="flex gap-[10px] ">
                          <Select
                              defaultValue="+91"
                              style={{ width: 80, marginTop: '5px', flex: 0.2, backgroundColor: '#F4F4F4' }}
                              className='border-[1px] text-[14px] rounded-[4px] p-[0] mt-[5px] bg-[#F4F4F4]'
                          >
                              <option value="+91">+91</option>
                          </Select>
                          <input
                              name={'phone'}
                              type={'text'} 
                              inputMode="numeric"
                              className={"flex-[0.8] border-[1px] text-[14px] rounded-[4px] p-[10px] mt-[5px] bg-[#F4F4F4] rounded-[12px] appearance-none"} 
                              placeholder={'Enter your Mobile Number'} value={formData?.phone} onChange={handleChange}
                          />
                      </div>
                  </div> */}
  
              <div className="col-span-2">
                <Input
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Enter your Password"
                  className="bg-[#F4F4F4] rounded-[12px]"
                  handleChange={handleChange}
                  value={formData?.password}
                  onBlur={validate}
                  errTxt={formErr?.password?.msg}
                />
              </div>
              <div className="col-span-2">
                <Input
                  label="Confirm Password"
                  name="confirm_password"
                  type="password"
                  placeholder="Enter your Password"
                  className="bg-[#F4F4F4] rounded-[12px]"
                  handleChange={handleChange}
                  value={formData?.confirm_password}
                  onBlur={validate}
                  errTxt={formErr?.confirm_password?.msg}
                />
              </div>
            </div>
  
            <button
              className="bg-[#FD3798] text-white font-bold text-lg py-3 my-3 w-[70%] rounded-full mx-auto "
              onClick={handleLogin}
            >
              Sign up
            </button>
  
            <p className="text-[#000] text-center text-[#000]/[0.7] mt-[15px] font-medium">
              Have a DigiTracker account?{" "}
              <span
                className="text-right text-blue-500 hover:underline cursor-pointer"
                onClick={() => {
                  navigate(LOGIN);
                }}
              >
                Sign In
              </span>
            </p>
          </div>
          <div className="col-span-2 max-md:hidden flex flex-col justify-center items-center bg-[#D9D9D9] text-center space-y-4">
            <h1 className="text-4xl font-bold">Welcome Back</h1>
            <p className="font-light">Keep connected with us
Please login with your info</p>
            <a href="/login" className="bg-[#FD3798] text-white font-bold text-lg py-3 px-10 rounded-full">LOGIN</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
