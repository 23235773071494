import React from "react";
import { Modal as Mod } from "antd";

const Modal = ({
  children,
  isOpen,
  handleSubmit,
  handleCancel,
  width,
}: any) => {
  return (
    <Mod
      width={width}
      footer={null}
      open={isOpen}
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      {children}
      <div className="flex justify-end gap-[20px] mt-[30px]">
        <button
          className="border-black border-[1px]  py-[8px] px-[20px] rounded-[4px] text-black  "
          onClick={handleCancel}
        >
          {" "}
          Cancel
        </button>
        <button
          className="bg-black py-[8px] px-[20px] rounded-[4px] text-white  "
          onClick={handleSubmit}
        >
          {" "}
          Save
        </button>
      </div>
    </Mod>
  );
};

export default Modal;
